import {Component, Inject} from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';

/* Usage:

    import { MessagemDialog } from 'src/app/dialogs/message/message'

    const dialogRef = this.dialog.open(MessageDialog, {data: {title: "Save changes", message:"Please enter comments and then save."}}
        );

    dialogRef.afterClosed().subscribe( () => {
        console.log("message dialog dismissed");
    });
*/

export interface MessageDialogData {
    title: string;
    message: string;
}

@Component({
    selector: 'message-dialog',
    templateUrl: 'message.html',
})

export class MessageDialog {
    constructor(@Inject(MAT_DIALOG_DATA) public data: MessageDialogData) {}
    }

