// Generic JavaScript utilities with no dependencies.

export class Utils
{
    static debugLoggingOn:boolean = true;
    static logIndent = 0;

    static log(...args: any[])
	{
		let indent = "";
		for (let i = 0; i < Utils.logIndent; i++)
			indent += " ";
		
		if (Utils.debugLoggingOn || typeof Utils.debugLoggingOn == 'undefined')
		{
			if (args.length == 1)			console.log(indent, args[0]);
			else if (args.length == 2)		console.log(indent, args[0], args[1]);
			else if (args.length == 3)		console.log(indent, args[0], args[1], args[2]);
			else if (args.length == 4)		console.log(indent, args[0], args[1], args[2], args[3]);
			else if (args.length == 5)		console.log(indent, args[0], args[1], args[2], args[3], args[4]);
			else if (args.length == 6)		console.log(indent, args[0], args[1], args[2], args[3], args[4], args[5]);
			else
			{
				console.log(indent);
				for (let n=0; n < args.length; n++) // close enough...
					console.log(args[n]);
			}
		}
	}

		// Useful for debugging, so you can see what an object actually was *when it was logged*, rather than
		// its "current" value (stupid JavaScript! The logs *change* as values change)
	static logActual( obj:any )
	{
		if (typeof obj == 'undefined' || obj == null)
		{
			console.log("Could NOT logActual() since input object is undefined!");
			return;
		}

		try
		{
			let indent = "";
			for (let i = 0; i < Utils.logIndent; i++)
				indent += " ";

			console.log(indent, JSON.parse(JSON.stringify(obj)));
		}
		catch (e)
		{
			Utils.logError("logActual() failed to parse the object into a JSON and back.");
			console.trace();
		}
	}

	static logWarning(...args: any[])
	{
		let css = 'color: orange';

		let indent = "";
		for (let i = 0; i < Utils.logIndent; i++)
			indent += " ";
		
			// During start-up, this variable will be undefined if this is called from a service before app.ts can initialize it
		if (Utils.debugLoggingOn || typeof Utils.debugLoggingOn == 'undefined')
		{
			if (args.length == 1)			console.log('%c' + indent + args[0], css );
			else if (args.length == 2)		console.log('%c' + indent + args[0], css, args[1] );
			else if (args.length == 3)		console.log('%c' + indent + args[0], css, args[1], args[2]);
			else if (args.length == 4)		console.log('%c' + indent + args[0], css, args[1], args[2], args[3]);
			else if (args.length == 5)		console.log('%c' + indent + args[0], css, args[1], args[2], args[3], args[4]);
			else if (args.length == 6)		console.log('%c' + indent + args[0], css, args[1], args[2], args[3], args[4], args[5]);
			else
				Utils.log(args);
		}
	}

	static logError(...args: any[])
	{
		let css = 'color: red';

		let indent = "";
		for (let i = 0; i < Utils.logIndent; i++)
			indent += " ";
		
			// During start-up, this variable will be undefined if this is called from a service before app.ts can initialize it
		if (Utils.debugLoggingOn || typeof Utils.debugLoggingOn == 'undefined')
		{
			if (args.length == 1)			console.log('%c' + indent + args[0], css );
			else if (args.length == 2)		console.log('%c' + indent + args[0], css, args[1] );
			else if (args.length == 3)		console.log('%c' + indent + args[0], css, args[1], args[2]);
			else if (args.length == 4)		console.log('%c' + indent + args[0], css, args[1], args[2], args[3]);
			else if (args.length == 5)		console.log('%c' + indent + args[0], css, args[1], args[2], args[3], args[4]);
			else if (args.length == 6)		console.log('%c' + indent + args[0], css, args[1], args[2], args[3], args[4], args[5]);
			else
				Utils.log(args);
		}
	}

		// Pass color string in first parameter ("green", "red", "orange", etc.) Must be a valid CSS color name.
	static logInColor(color:string, firstItem:any, ...args: any[])
	{
		let css = 'color: ' + color;

		let indent = "";
		for (let i = 0; i < Utils.logIndent; i++)
			indent += " ";

		let length = args.length;
		
			// During start-up, this variable will be undefined if this is called from a service before app.ts can initialize it
		if (Utils.debugLoggingOn || typeof Utils.debugLoggingOn == 'undefined')
		{
			if (length == 0)			console.log('%c' + indent + firstItem, css );
			else if (length == 1)		console.log('%c' + indent + firstItem, css, args[0] );
			else if (length == 2)		console.log('%c' + indent + firstItem, css, args[0], args[1]);
			else if (length == 3)		console.log('%c' + indent + firstItem, css, args[0], args[1], args[2]);
			else if (length == 4)		console.log('%c' + indent + firstItem, css, args[0], args[1], args[2], args[3]);
			else if (length == 5)		console.log('%c' + indent + firstItem, css, args[0], args[1], args[2], args[3], args[4]);
			else
				Utils.log(args);
		}
	}

    		// When you want to join phrases that should be separated by commas, and the word "and" prior to the last phrase, if appropriate
		// i.e. "4 hours and 20 minutes" or "4 hours, 20 minutes, and 30 seconds"
		// combineSentences joinSentences
	static joinPhrases(phrases:Array<string>):string
	{
		let result = "";

		for (let n = 0; n < phrases.length; n++)
		{
			if (n > 0)
				result += " ";

			result += phrases[n];

			if (phrases.length > 2 && n < phrases.length-1)
				result += ",";

			if (n == phrases.length-2)
				result += " and";
		}

		return result;
	}
		

/** Returns a list of fields that changed. Properly iterates child arrays and objects.
    @changedFields: Pass in an empty array. Will be filled with keys of root-level fields that changed (or had children change)
**/
    static hasObjectChanged( obj:any, prevObj:any, changedFields:Array<string>|null = null ):boolean
    {
        if (!obj)
            return false;

        let anyChanged = false;
        for (const [key, value] of Object.entries(obj)) 
        {
            if ( Array.isArray(value) )
            {
            //    console.log("Checking array: ", key);
                if ( Utils.hasArrayChanged( obj[key], prevObj[key], null ) ) {
                    anyChanged = true;
                //    console.log("Found changed array: ", key, obj[key], prevObj[key])
                    if (changedFields)
                        changedFields.push(key);
                }
            }
            else if (typeof value === 'object') {
            //    console.log("Checking object: ", key);
                if ( Utils.hasObjectChanged( obj[key], prevObj[key], null ) ) {
                    anyChanged = true;
                //    console.log("Found changed object: ", key, obj[key], prevObj[key])
                    if (changedFields)
                        changedFields.push(key);
                }
            }
            else {
                    // We don't want 5 and "5" to be considered equivalent, so we use !== instead of !=
                if ( prevObj[key] !== obj[key] )
                {
                 //   console.log("Found changed value: ", key);
                    anyChanged = true;
                    if (changedFields)
                        changedFields.push(key);
                }
            }
        }

        return anyChanged;
    }

    static hasArrayChanged( arr:any, prevArr:any, changedFields:Array<string>|null = null ):boolean
    {
        if (arr.length != prevArr.length)
            return true;

        let anyChanged = false;
        for (let n=0; n < arr.length; n++) 
        {
            let value = arr[n];
            if ( Array.isArray(value) ) {
            //    console.log("   Checking sub-array: ", value);
                if ( Utils.hasArrayChanged( arr[n], prevArr[n], null ) ) {
                    anyChanged = true;
                //    console.log("Found changed array: ", arr[n] );
                    if (changedFields)
                        changedFields.push(n + '');
                }
            }
            else if (typeof value === 'object') {
            //    console.log("   Checking sub-object: ", value);
                if ( Utils.hasObjectChanged( arr[n], prevArr[n], null ) ) {
                    anyChanged = true;
                //    console.log("Found changed object: ", arr[n], prevArr[n] );
                    if (changedFields)
                        changedFields.push(n + '');
                }
            }
            else {
                    // We don't want 5 and "5" to be considered equivalent, so we use !== instead of !=
                if ( prevArr[n] !== arr[n] ) {
                    anyChanged = true;
                //    console.log("Found change in array index: ", n, arr[n]);
                    if (changedFields)
                        changedFields.push(value + '');
                }
            }
        }

        return anyChanged;
    }

    	// Generates a GUID based on both the current time and random numbers.
		// Can potentially generate an identical guid if called at the exact same millisecond as another call.
		// From Briguy37's answer at:
		// http://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
	static generateGUID():string
	{
		let d = new Date().getTime();
		if (typeof performance !== 'undefined' && typeof performance.now === 'function'){
			d += performance.now(); //use high-precision timer if available
		}
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
			let r = (d + Math.random() * 16) % 16 | 0;
			d = Math.floor(d / 16);
			return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
		});
	}

    //----------------------------------------------------------------------------------
    // Functions from Geoff's old QMS (merely ported to TypeScript)
    //----------------------------------------------------------------------------------

        // Geoff's function:
    static generateQuickGuid():string {
        return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    }

    static getISODate(sec:number) {
        var d = new Date(sec*1000)
        return d.getFullYear() + "-" + this.pad(d.getMonth()+1, 2) + "-" + this.pad(d.getDate(), 2) + " " + 
            this.pad(d.getHours(),2) + ":" + this.pad(d.getMinutes(),2);
    }

    static startsWith(str1:number | string, str2:number | string) {
        if (!isNaN(<number>str1)) 
            str1 = str1.toString();
        if (!isNaN(<number>str2)) 
            str2 = str2.toString();
        return (<string>str1).substring(0, (<string>str2).length) === str2;
    }

    static occurrences( str:any, subStr:any, allowOverlapping:boolean ) {
        str += ""; 
        subStr += "";
        if (subStr.length<=0) 
            return str.length+1;
    
        let n:number = 0, pos:number = 0;
        let step= (allowOverlapping) ? (1) : (subStr.length);
    
        while (true) {
            pos=str.indexOf(subStr,pos);
            if (pos>=0) { 
                n++; 
                pos += step; 
            } 
            else 
                break;
        }
        return n;
    }

    static pad( n:number|string, width:number, z:string = '0' ):string {
        n = n + '';
        return (n.length >= width) ? n : new Array(width - n.length + 1).join(z) + n;
    }

        // WARNING: This CHANGES the input array!!! This results in a change being detected on the job due to the sort. 
        // Better to sort once, right after loading your record, than in HTML:
        //     *ngFor="let item of Utils.sortBy( job.tests, 'testid')"
    static sortBy( arr:Array<any>, prop: string):Array<any> {
        if (arr)
            return arr.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
        else
            return arr;
    }

        // WARNING: This CHANGES the input array!!! This results in a change being detected on the job due to the sort. 
        // Reviews might not yet contain a date. We must take that into consideration when sorting by date.
    static sortByDate( arr:Array<any>):Array<any> {
        if (arr)
            return arr.sort((a, b) => {
                if (typeof a.date == 'undefined')
                    return 1;
                else if (typeof b.date == 'undefined')
                    return -1;
                else
                    return a.date > b.date ? 1 : a.date === b.date ? 0 : -1
            });
        else
            return arr;
    }


    static getDateFromMongoDBTimestamp( timestampObj:any )
    {
        if (timestampObj && timestampObj.$date && timestampObj.$date.$numberLong )
        {
            // MongoDB native Date is milliseconds since epoch in UTC, same as what JS Date() takes
            var date = new Date(parseInt(timestampObj.$date.$numberLong));
            return date.getFullYear() + '-' +
                (date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : '' + (date.getMonth() + 1)) + '-' +
                (date.getDate() < 10 ? '0' + date.getDate() : '' + date.getDate()) + ' ' +
                (date.getHours() < 10 ? '0' + date.getHours() : '' + date.getHours()) + ':' +
                (date.getMinutes() < 10 ? '0' + date.getMinutes() : '' + date.getMinutes()) + ':' +
                (date.getSeconds() < 10 ? '0' + date.getSeconds() : '' + date.getSeconds());
        }
        return "";
    }
}