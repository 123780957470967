import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QMSConst } from '../../qms.classes';

    // AWESOME: Angular actually reports an error on 'build' if the HTML* references a property not in this interface!! Sweet!
export interface ReviewJobData {
    title: string;
    subtitle: string;
    body: string;
    comments: string;
    users: CRMUser[];
    owner: string;
    statusNew: number;
    statusMenuItems:Array<{id:number, type:string}>;
    showStatusMenu: boolean;
    warning: string;
}

@Component({
    selector: 'reviewjob-dialog',
    templateUrl: 'reviewJob.html',
})

export class ReviewJobDialog {
    passFailChoice: number = -1;
    readonly QMSConst = QMSConst;
    
    constructor(@Inject(MAT_DIALOG_DATA) public data: ReviewJobData) {

    }
}

