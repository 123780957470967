<div *ngIf="currentDatabase.length > 0">
    <br />
    <b style="color:purple">{{currentDatabase}}</b>
    <br /><br />
</div>

<div class="col-md-12">
    <div class="row">
        <div class="col-sm-12">
            <div class="panel panel-primary">
                <div class="panel-heading">
                    <div class="row">
                    <div class="col-md-2">
                        <h3 class="panel-title">{{jobid}}</h3>
                    </div>
                    
                 </div>
                </div>
                <div class="panel-body">

                    <div *ngIf="job.jobid">
                        <mat-card style='background-color:#edf8fd; margin-bottom:30px;'>
                            <div class="row">
                                <div class="col-md-2">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Status:</mat-label>
                                        <mat-select [(value)]="jobStatus" (selectionChange)="statusOwnerChanged($event)">
                                            <mat-option *ngFor="let item of statusMenuItems" [value]="item.id">{{item.type}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div class="col-md-2">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Owner:</mat-label>
                                        <mat-select [(value)]="jobOwner" (selectionChange)="statusOwnerChanged($event)">
                                            <mat-option value="">None</mat-option>
                                            <mat-option *ngFor="let user of users" value="{{user.username}}">{{user.fullname}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                
                                <div class="col-md-1">
                                    <button [disabled]="isChangingStatus || (prevJob.statusNew == jobStatus && prevJob.owner == jobOwner)" mat-raised-button color="primary" (click)="changeStatusClicked($event)">Reassign</button>
                                </div>
                                <div class="col-md-6">
                                    <b>{{statusMsg}}</b>
                                    <span *ngIf="isChangingStatus" style="display: inline-block; height: 80px;">
                                        <mat-spinner [diameter]="progressSpinnerSize" style="margin: 15px;"></mat-spinner>
                                    </span>
                                </div>
                            </div>
                            <div class="row" *ngIf="isDisabled">
                                <b>Editing of this job is disabled because it is closed. To edit it, first change its Status and Save.</b>
                                <br />
                            </div>
                        </mat-card>

                        <mat-card style='background-color:#f9faffca;  margin-bottom:30px;'>
                            <div class="row">
                                <div class="col-md-12">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Executive Summary</mat-label>
                                        <input matInput [(ngModel)]='job.summary' (input)="textFieldChanged($event)" [disabled]="isDisabled" autocomplete="off" placeholder="One line summary. This will be used in release notes (unless categorized as 'Other')">
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-2">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Type</mat-label>
                                        <mat-select [(value)]="job.job_type" (selectionChange)="selectFieldChanged($event)" [disabled]="isDisabled">
                                            <mat-option value="---">Not Selected</mat-option>
                                            <mat-option value="Major">Major Change</mat-option>
                                            <mat-option value="Minor">Minor Change</mat-option>
                                            <mat-option value="Bugfix">Bugfix</mat-option>
                                            <mat-option value="Vendor">Vendor Change</mat-option>
                                            <mat-option value="Other">Other</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-3">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Branches</mat-label>
                                        <mat-select [(value)]="job.branches" multiple (selectionChange)="selectFieldChanged($event)" [disabled]="isDisabled">
                                            <mat-option *ngFor="let item of QMSConst.JOB_BRANCHES" [value]="item">{{item}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <!--   <div class="col-md-7">
                                    <mat-chip-list selectable="false">
                                        <mat-chip *ngFor="let item of job.branches">{{item}}</mat-chip>
                                    </mat-chip-list>
                                </div>
                                -->
                                <div class="col-md-3"> 
                                    <mat-form-field appearance="outline">
                                        <mat-label>Risk</mat-label>
                                        <mat-select [(value)]="job.risklevel" (selectionChange)="selectFieldChanged($event)" [disabled]="isDisabled">
                                            <mat-option value="tbd">TBD</mat-option>
                                            <mat-option value="no">Change does not affect Risks/Hazards</mat-option>
                                            <mat-option value="yes">Change affects Risks/Hazards</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>

                            <mat-form-field appearance="outline">
                                <mat-label>Requirements</mat-label>
                                <textarea [(ngModel)]='job.requirements' (input)="textFieldChanged($event)" matInput style="min-height:100px;" cdkTextareaAutosize [disabled]="isDisabled" autocomplete="off"></textarea>
                            </mat-form-field>

                            <mat-form-field appearance="outline">
                                <mat-label>Design</mat-label>
                                <textarea [(ngModel)]='job.design' (input)="textFieldChanged($event)" matInput style="min-height:100px;" cdkTextareaAutosize [disabled]="isDisabled" autocomplete="off"></textarea>
                            </mat-form-field>

                            <h3>Tests</h3>
                            Include all tests (including corner cases) to adequately test the above requirements.

                            <!-- No tests checkbox, reason & description -->
                            <div class="row" style="margin-top:20px; margin-bottom:20px">
                                <div *ngIf="noTests()">
                                    <div class="col-md-1" style="margin-left:22px">
                                        <label class="checkbox">
                                            <input id="noTestsCk" type="checkbox" [checked]="job.notests" (change)="job.notests = !job.notests; updateChangedFields();" [disabled]="isDisabled"> No Tests
                                        </label> 
                                    </div>

                                    <div class="col-md-2" *ngIf="job.notests">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Reason</mat-label>
                                            <mat-select [(value)]="job.no_test_select" (selectionChange)="selectFieldChanged($event)" [disabled]="isDisabled">
                                                <mat-option value="compile">Compilation</mat-option>
                                                <mat-option value="vendor">Vendor Testing:</mat-option>
                                                <mat-option value="docs">Documentation only</mat-option>
                                                <mat-option value="other">Other:</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <div class="col-md-6" *ngIf="job.notests">
                                        <mat-form-field appearance="outline" *ngIf="job.no_test_select=='vendor' || job.no_test_select=='other'">
                                            <mat-label>Details</mat-label>
                                            <input matInput [(ngModel)]='job.no_test_reason' (input)="textFieldChanged($event)" placeholder="Enter details" [disabled]="isDisabled">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>

                                <!-- Tests -->
                            <div class="row">
                                <mat-card *ngFor="let test of job.tests" 
                                            style="margin-bottom:15px; background-color:#fffef9b6;"
                                            [ngStyle]="{'margin-left': getTestLeftMargin(test) + 'px', 'margin-right': getTestRightMargin(test) + 'px'}">
                                    <div class="cool_test_label" style="margin-bottom: 20px"><b>Test #{{test.testid}}</b>
                                    </div>

                                    <div class="row" style="margin-bottom:5px"> <!-- 5px: add a little space between "Include in Master Test Plan" and the Result textarea -->
                                        <div style="float: right; margin-right:10px">
                                            <mat-slide-toggle [(ngModel)]="test.inc_master" style="font-weight: normal" (change)="updateChangedFields()" [disabled]="isDisabled">
                                                Include in Master Test Plan
                                            </mat-slide-toggle>
                                        </div>
                                    </div>

                                    <table class="table cs_test_table">
                                        <tr>
                                            <td style="width:55%; vertical-align: top;">
                                                <mat-form-field appearance="outline">
                                                    <mat-label>Description</mat-label>
                                                    <textarea [(ngModel)]='test.description' (input)="textFieldChanged($event)" matInput style="min-height:30px;" [disabled]="isDisabled" cdkTextareaAutosize autocomplete="off"></textarea>
                                                </mat-form-field>
                                            </td>
                                            <td style="width:5%">
                                            </td>
                                            <td style="width:40%; vertical-align: top;">
                                                <mat-form-field appearance="outline">
                                                    <mat-label>Expected Result</mat-label>
                                                    <textarea [(ngModel)]='test.expected_result' (input)="textFieldChanged($event)" matInput cdkTextareaAutosize [disabled]="isDisabled" autocomplete="off"></textarea>
                                                </mat-form-field>

                                                <div class="row">
                                                        <button (click)="addChildTestButtonPushed(test)" mat-raised-button style="margin-left:20px" [disabled]="isDisabled">
                                                            <i class="fa fa-plus" aria-hidden="true"> New Subtest</i>
                                                        </button> 
                                                        <span style="float:right; margin-right: 20px">
                                                            <button (click)="deleteTestButtonPushed(test.testid)" mat-raised-button color="warn" title="Delete this test" [disabled]="isDisabled">
                                                                <i class="fa fa-trash-o" aria-hidden="true"></i> Delete
                                                            </button>
                                                        </span>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                </mat-card>
                            </div>

                                <!-- Add Test button-->
                            <div class="row" style="margin-bottom:10px">
                                <span style="float:right; margin-right: 60px">
                                    <button (click)="addTestButtonPushed()" mat-raised-button [disabled]="isDisabled"><i class="fa fa-plus" aria-hidden="true"></i> Add Test</button> 
                                </span>
                            </div>

                                <!-- Save Changes to job -->
                            <div class="row">
                                <div class="col-md-2">
                                    <button [disabled]="isSaving || changedFields.length == 0 || isDisabled" mat-raised-button color="primary" (click)="saveButtonClicked($event)">Save Changes</button>
                                </div>
                                <div class="col-md-8">
                                    <span *ngIf="isSaving" style="display: inline-block; height: 80px;">
                                        <mat-spinner [diameter]="progressSpinnerSize" style="margin: 15px;"></mat-spinner>
                                    </span>
                                    <b>{{saveResult}}</b>
                                    {{changedSentence}}
                                </div>
                            </div>
                        </mat-card>

                        <!-- Reviews / Approvals -->
                        <mat-card style='background-color:#edf8fd; margin-bottom:30px;'>
                            <div class="row cool_test">
                                <div class="col-md-12">
                                    <h2>Reviews / Approvals</h2>
                                    <table class="table-condensed">
                                        <tbody>
                                            <tr>
                                                <td colspan="4"><b>Current Status: {{getJobCurrentStatusString()}}</b></td>
                                            </tr>
                                            <tr *ngFor="let review of job.reviews">
                                                <td style="width:12%">
                                                    <span class="label label-success" *ngIf="review.status == 'Pass'"><i class="icon-ok"></i> Passed!</span>
                                                    <span class="label label-important" *ngIf="review.status == 'Fail'"><i class="icon-remove"></i> Failed!</span>
                                                <!--
                                                    <div *ngIf="review.status != 'Pass' && review.status != 'Fail'">
                                                        <button mat-raised-button (click)="doReview(review.type, review.user)">Review</button>
                                                    </div>
                                                -->
                                                </td>
                                                <td style="width:12%">
                                                    {{getReviewTypeString(review.type)}}
                                                </td>
                                                <td style="width:17%">
                                                    {{review.user}}
                                                </td>
                                                <td style="width:20%">
                                                    {{review.date && review.date.substring(0, 16)}} <!-- review.date is of string type in the form of 'YYYY-MM-DD hh:mm:ss' in the database. Hide the seconds part. -->
                                                </td>
                                         <!--      <td style="width:20px">
                                                    <button mat-raised-button (click)="removeReview(review.user,review.type)"><i class="fa fa-trash-o"></i></button>
                                                </td>
                                        --> 
                                                <td>
                                                    <!--<button *ngIf="review.comments.length > 0" data-html="true" popover-trigger="mouseenter" popover-title="{{review.user}}" 
                                                    popover-placement="left" popover="{{review.comments}}" class="btn"><i class="icon-comment">...</i></button>-->
                                                    <span *ngIf="review.comments && review.comments.length > 0">{{review.comments}}</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                <!--   <p style="margin-top:20px"><button mat-raised-button (click)="addReviewer()"><i class="fa fa-plus"></i> Add Reviewer</button></p>    -->
                                    
                                    <div class="row" style="margin-top: 20px" *ngIf="job.statusNew != QMSConst.JOB_STATUS_CLOSED && !jobMayBeApproved()">
                                        <div class="col-md-12"><b>{{jobMayNotBeApprovedMsg}}</b></div>
                                    </div>
                                    <div class="row" style="margin-top: 20px" *ngIf="!isDisabled && jobMayBeApproved()">
                                        <div class="col-md-12">
                                            <button mat-raised-button color="primary" (click)="changeStatusToNextStep()" [disabled]="isChangingStatus">{{getJobApproveLabel()}}</button>&nbsp;
                                            <button *ngIf="job.statusNew == QMSConst.JOB_STATUS_IN_DEVELOPMENT"
                                                    mat-raised-button (click)="requestCodeReview()" [disabled]="isChangingStatus">Request Code Review</button>&nbsp;
                                            <button *ngIf="job.statusNew != QMSConst.JOB_STATUS_PLANNED"
                                                    mat-raised-button color="warn" (click)="failJob()" [disabled]="isChangingStatus">{{getJobFailLabel()}}</button>&nbsp;
                                            <b>{{approveJobMsg}}</b>
                                            <span *ngIf="isChangingStatus" style="display: inline-block; height: 80px;">
                                                <mat-spinner [diameter]="progressSpinnerSize" style="margin: 15px;"></mat-spinner>
                                            </span>
                                        </div>
                                    </div>
                                        
                                </div>
                            </div>
                        </mat-card>
                    
                            <!-- Tabs -->
                        <mat-tab-group animationDuration="0ms" backgroundColor="primary">
                            <mat-tab label="Code Changes ({{jobChanges.length}})">
                                <mat-card style='background-color:#f9fffcca;  margin-bottom:30px;'>
                                    <table class="table">
                                        <thead>
                                            <th>CL#</th>
                                            <th>Date</th>
                                            <th>Branch</th>
                                            <th>Programmer</th>
                                            <th>Description</th>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let change of jobChanges">
                                                <td><a href="http://p4aim/changes/{{change.change}}" target="_blank">{{change.change}}</a></td>
                                                <td nowrap>{{change.cldate.substring(0, 16)}}</td> <!-- change.cldate is of string type in the form of 'YYY-MM-DD hh:mm' in the database. Make sure seconds, if any, are hidden-->
                                                <td>{{change.branches}}</td>
                                                <td>{{change.user}}</td>
                                                <td>{{change.description}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </mat-card>
                            </mat-tab>
                            <mat-tab label="Test Worksheets ({{testWorksheets.length}})">
                                <mat-card style='background-color:#f9fffcca;  margin-bottom:30px;'>
                                    <div>
                                        <div *ngIf="testWorksheets.length == 0">
                                            <p>No Completed Test Worksheets</p>
                                        </div>
                                        <div *ngIf="testWorksheets.length > 0">
                                            <table class="table">
                                                <thead><tr><th>Tester</th><th>Date</th><th>Suite-CL</th><th>Pass/Fail/Not Tested</th><th>Tickets</th></thead>
                                                <tbody>
                                                    <tr *ngFor="let tw of testWorksheets">
                                                        <td>{{tw.testerUsername}}</td>
                                                        <td>{{Utils.getDateFromMongoDBTimestamp(tw.date_modified).substring(0, 16)}} </td> <!-- getDateFromMongoDBTimestamp() returns 'YYYY-MM-DD hh:mm:ss'. Hide seconds -->
                                                        <td>{{tw.suite}}-{{tw.buildnum}}</td>
                                                        <td><a class="btn btn-default" href='#/tw/{{jobid}}/{{tw._id.$oid}}'>{{tw.num_pass}}/{{tw.num_fail}}/{{tw.num_nt}}</a></td>
                                                        <td>
                                                            <span *ngFor="let test of tw.tests">
                                                                <a *ngIf="test.ticketid" href='/#/ticket/{{test.ticketid}}'>{{test.ticketid}}&nbsp;</a>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <a href="/#/tw/{{jobid}}/new" class="btn btn-info">New Test Worksheet</a> 
                                </mat-card>
                            </mat-tab>
                            <mat-tab label="History"> 
                                <mat-card style='background-color:#f9fffcca;  margin-bottom:30px;'>
                                    <!-- Log -->
                                    <table>
                                        <thead>
                                            <th style="width:10%">User</th>
                                            <th style="width:18%">Date</th>
                                            <th style="width:36%">Action</th>
                                            <th style="width:36%">Comments</th>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of job.logs">
                                                <td>{{item.user}}</td>
                                                <td>{{item.date}}</td>
                                                <td>{{item.action}}</td>
                                                <td>{{item.comments}}</td>
                                            </tr>
                                        </tbody>
                                    </table>   
                                </mat-card>
                            </mat-tab>
                        </mat-tab-group>

                    </div>
                    <div *ngIf="!job.jobid && jobNotFoundMsg.length == 0">
                        <span style="display: inline-block; height: 80px;">
                            <mat-spinner [diameter]="progressSpinnerSize" style="margin: 15px;"></mat-spinner>
                        </span>
                    </div>

                    <p style="color:red"><b>{{httpErrorMsg}}</b></p>
                    {{jobNotFoundMsg}}
                </div>
            </div>
        </div>
    </div>
</div>
