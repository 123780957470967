<div *ngIf="currentDatabase.length > 0">
    <br />
    <b style="color:purple">{{currentDatabase}}</b>
    <br /><br />
</div>

<div class="col-md-12">
    <div class="row">
        <div class="col-sm-12">
            <div class="panel panel-primary">
                <div class="panel-heading">
                    <div class="row">
                        <div class="col-md-2">
                            <h3 class="panel-title">Ticket #{{ticketid}}</h3>
                        </div>
                    </div>
                </div>
                

                <div class="panel-body">

                    <div *ngIf="ticket.id">

                        <div class="row" *ngIf="isDisabled">
                            <b>Editing of this ticket is disabled because it is closed. To edit it, first change its Status to 'Open' and Save.</b>
                            <br />
                        </div>

                        <div class="row" *ngIf="ticket.jobid && ticket.jobid.length > 0" style="margin-top:10px; margin-bottom:10px;">
                            A job was created from this ticket:
                            <a class="btn btn-mini vcenter" href="/#/job/job{{ticket.jobid}}" target="_blank" title="Open job{{ticket.jobid}} in New Window">job{{ticket.jobid}}<i class="fa fa-share" aria-hidden="true"></i></a>
                        </div>
                        
                        <mat-card style='background-color:#f9faffca;  margin-bottom:30px;'>
                            <div class="row">
                                <div class="col-md-2">
                                    <button class="btn btn-lg" (click)="ticket.flagged = (ticket.flagged) ? 0 : 1; updateChangedFields();" [disabled]="isDisabled">
                                        <i class="fa fa-flag" aria-hidden="true" [ngStyle]="{'color': (ticket.flagged) ? 'red' : 'black'}"></i>
                                    </button>
                                 
                                </div>
                                <div class="col-md-3">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Status</mat-label>
                                        <mat-select [(value)]="ticket.status" (selectionChange)="statusOwnerChanged($event)">
                                            <mat-option [value]="minusOne">None</mat-option>
                                            <mat-option *ngFor="let item of QMSConst.TICKET_STATUSES;" [value]="item.id">{{item.type}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                
                                <div class="col-md-2">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Owner</mat-label>
                                        <mat-select [(value)]="ticket.owner" (selectionChange)="statusOwnerChanged($event)">
                                            <mat-option value="">None</mat-option>
                                            <mat-option *ngFor="let user of users" value="{{user.username}}">{{user.fullname}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div class="col-md-3">
                                    <mat-checkbox [(ngModel)]="ticket.isprivate" color="primary" (ngModelChange)="updateChangedFields()" [disabled]="isDisabled">Risk Potential</mat-checkbox>
                                </div>
                            </div>
                            

                            <div class="row">
                                <div class="col-md-3">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Application</mat-label>
                                        <input matInput [(ngModel)]='ticket.app' (input)="textFieldChanged($event)" [disabled]="isDisabled" autocomplete="off">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-1" style="margin-left:-30px;">
                                    <mat-form-field appearance="outline">
                                        <mat-label></mat-label>
                                        <mat-select (selectionChange)="appChanged($event)" [disabled]="isDisabled">
                                            <mat-select-trigger></mat-select-trigger> <!-- Show an empty menu, not the most recently selected item -->
                                            <mat-option *ngFor="let item of apps" [value]="item">{{item.module}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div class="col-md-8">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Reported</mat-label>
                                        <input matInput [(ngModel)]='ticket.reported' (input)="textFieldChanged($event)" [disabled]="isDisabled" autocomplete="off">
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Summary</mat-label>
                                        <input matInput [(ngModel)]='ticket.summary' (input)="textFieldChanged($event)" [disabled]="isDisabled" autocomplete="off">
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-2">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Affects Branches:</mat-label>
                                        <mat-select [(value)]="ticket.branches" multiple (selectionChange)="selectFieldChanged($event)" [disabled]="isDisabled">
                                            <mat-option *ngFor="let item of QMSConst.TICKET_BRANCHES" [value]="item">{{item}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div class="col-md-2">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Found in:</mat-label>
                                        <mat-select [(value)]="ticket.source" (selectionChange)="selectFieldChanged($event)" [disabled]="isDisabled">
                                            <mat-option *ngFor="let item of QMSConst.TICKET_SOURCES" [value]="item">{{item}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div class="col-md-3" *ngIf="ticket.source == 'Other'">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Other:</mat-label>
                                        <input matInput [(ngModel)]='ticket.source_other' (input)="textFieldChanged($event)" [disabled]="isDisabled" autocomplete="off">
                                    </mat-form-field>
                                </div>
                            </div>

                            <!--
    static readonly TICKET_SOURCES = ["2015.XX","2013.4", "2013.3", "2013.2", "2013.1","2013.0","2013.XX","2012.7","2012.6","2012.5","2012.4","2012.3","2012.2","2012.1","2012.0","2012.XX","Other"];
                            -->

                            <div class="row">
                                <div class="col-md-2"> 
                                    <mat-form-field appearance="outline">
                                        <mat-label>Vendor</mat-label>
                                        <mat-select [(value)]="ticket.vendor" (selectionChange)="selectFieldChanged($event)" [disabled]="isDisabled">
                                            <mat-option *ngFor="let item of QMSConst.TICKET_VENDORS" [value]="item">{{item}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-2"> 
                                    <mat-form-field appearance="outline">
                                        <mat-label>Severity</mat-label>
                                        <mat-select [(value)]="ticket.severity" (selectionChange)="selectFieldChanged($event)" [disabled]="isDisabled">
                                            <mat-option *ngFor="let item of QMSConst.TICKET_SEVERITY" [value]="item">{{item}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-2"> 
                                    <mat-form-field appearance="outline">
                                        <mat-label>Platform</mat-label>
                                        <mat-select [(value)]="ticket.platform" (selectionChange)="selectFieldChanged($event)" [disabled]="isDisabled">
                                            <mat-option *ngFor="let item of QMSConst.TICKET_PLATFORMS" [value]="item">{{item}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-2"> 
                                    <mat-form-field appearance="outline">
                                        <mat-label>Probability</mat-label>
                                        <mat-select [(value)]="ticket.probability" (selectionChange)="selectFieldChanged($event)" [disabled]="isDisabled">
                                            <mat-option *ngFor="let item of QMSConst.TICKET_PROBABILITY" [value]="item">{{item}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Detailed Description</mat-label>
                                        <textarea [(ngModel)]='ticket.description' (input)="textFieldChanged($event)" matInput style="min-height:100px;" cdkTextareaAutosize
                                            placeholder="Not required if summary adequately describes the issue" [disabled]="isDisabled" autocomplete="off"></textarea>
                                    </mat-form-field>
                                </div>

                                <div class="col-md-12">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Work-around</mat-label>
                                        <textarea [(ngModel)]='ticket.workaround' (input)="textFieldChanged($event)" matInput style="min-height:100px;" cdkTextareaAutosize
                                            placeholder="Only required if ticket affects a software release" [disabled]="isDisabled" autocomplete="off"></textarea>
                                    </mat-form-field>
                                </div>
                            </div>
                            
<!--

                            <div class="row">
                                <div class="col-md-3"> 
                                    <mat-form-field appearance="outline">
                                        <mat-label>Risk</mat-label>
                                        <mat-select [(value)]="job.risklevel" (selectionChange)="selectFieldChanged($event)">
                                            <mat-option value="none">Change introduces no risk</mat-option>
                                            <mat-option value="small">Change may introduce small risk</mat-option>
                                            <mat-option value="large">Change introduces risk</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>

                            <mat-form-field appearance="outline">
                                <mat-label>Requirements</mat-label>
                                <textarea [(ngModel)]='job.requirements' (input)="textFieldChanged($event)" matInput style="min-height:100px;" cdkTextareaAutosize></textarea>
                            </mat-form-field>

                -->

                                <!-- Save ticket and Create P4 Job-->
                            <div class="row">
                                <div class="col-md-2">
                                    <button [disabled]="isSaving || changedFields.length == 0" mat-raised-button color="primary" (click)="saveTicket()">Save Changes</button>
                                </div>
                                <div class="col-md-2" *ngIf="ticketid != 'new'">
                                    <button [disabled]="isSaving || isDisabled"  mat-raised-button (click)="createJobFromTicket()">Create P4 Job and Close</button>
                                </div>
                                <div class="col-md-8">
                                    <span *ngIf="isSaving" style="display: inline-block; height: 80px;">
                                        <mat-spinner [diameter]="progressSpinnerSize" style="margin: 15px;"></mat-spinner>
                                    </span>
                                    <b>{{saveResult}}</b>
                                    {{changedSentence}}<p style="color:red"><b>{{httpErrorMsg}}</b></p>
                                </div>
                            </div>

                                <!-- Comments -->
                            <mat-card style='background-color:#fffdf0ca;  margin-bottom:30px; margin-top:30px;'>
                                <div class="row">
                                    <div class="col-md-3">
                                        <button [disabled]="newComment.length == 0 || isDisabled" mat-raised-button color="primary" style="margin-top:90px"
                                            (click)="addComment()">Add Comment and Save</button>
                                    </div>
                                    <div class="col-md-9">
                                        <mat-form-field appearance="outline">
                                            <mat-label>New Comment</mat-label>
                                            <textarea [(ngModel)]='newComment' (input)="textFieldChanged($event)" matInput style="min-height:100px;" cdkTextareaAutosize [disabled]="isDisabled" autocomplete="off"></textarea>
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div class="row" *ngFor="let comment of ticket.comments; index as idx;" style="margin-top:20px; margin-bottom:20px;">
                                    <div class="col-md-2">
                                            {{comment.name}} <br/>
                                            {{comment.date}} <br />
                                        
                                    </div>
                                    <div class="col-md-1">
                                        <button mat-raised-button (click)="deleteComment(idx)" [disabled]="isDisabled"><i class="fa fa-trash-o" aria-hidden="true"></i></button> 
                                    </div>
                                    <div class="col-md-9">
                                        {{comment.comment}}
                                    </div>
                                </div>
                            </mat-card>

                       </mat-card>
                    
                            <!-- Tabs -->
                        <mat-tab-group animationDuration="0ms" backgroundColor="primary">
                            <mat-tab label="Files">
                                <mat-card style='background-color:#f9fffcca; margin-bottom:30px;'>
                                    [Not yet implemented. Files drag-and-drop area to go here.]
                                </mat-card>
                            </mat-tab>
                            <mat-tab label="History"> 
                                <mat-card style='background-color:#f9fffcca; margin-bottom:30px;'>
                                    <!-- Log -->
                                    <table>
                                        <thead>
                                            <th style="width:14%">User</th>
                                            <th style="width:18%">Date</th>
                                            <th style="width:34%">Action</th>
                                            <th style="width:34%">Comments</th>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of ticket.logs">
                                                <td>{{item.user}}</td>
                                                <td>{{item.date}}</td>
                                                <td>{{item.action}}</td>
                                                <td>{{item.comments}}</td>
                                            </tr>
                                        </tbody>
                                    </table>   
                                </mat-card>
                            </mat-tab>
                        </mat-tab-group>
                    
                    </div>
                    <div *ngIf="!ticket.id && !ticket.summary">
                        <span style="display: inline-block; height: 80px;">
                            <mat-spinner [diameter]="progressSpinnerSize" style="margin: 15px;"></mat-spinner>
                        </span>
                    </div>

                    <p style="color:red"><b>{{httpErrorMsg}}</b></p>

                </div>
            </div>
        </div>
    </div>
</div>
