
<div class="col-md-12">
    <div class="row">
        <div class="col-sm-12">
            <div class="panel panel-primary">
                <div class="panel-heading">
                    <h3 class="panel-title">Tickets</h3>
                    
                </div>
                <div class="panel-body">
                        <!-- Query params -->
                    <div class="row" *ngIf="!ishomepage">
                        <div class="col-md-3">
                            <mat-form-field appearance="outline">
                                <mat-label>Statuses</mat-label>
                                <mat-select [(value)]="queryStatuses" multiple (closed)="queryMenuChanged()" (selectionChange)="statusMenuItemClicked($event)">
                                    <mat-option value="Clear">CLEAR SELECTIONS</mat-option>
                                    <mat-option *ngFor="let item of QMSConst.TICKET_STATUSES" [value]="item.id">{{item.type}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Owner</mat-label>
                                <mat-select [(value)]="queryOwner" (selectionChange)="queryMenuChanged()">
                                    <mat-option value="">All</mat-option>
                                    <mat-option *ngFor="let user of users" value="{{user.username}}">{{user.fullname}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="col-md-2">
                            <mat-form-field appearance="outline">
                                <mat-label>Vendor</mat-label>
                                <mat-select [(value)]="queryVendor" (selectionChange)="queryMenuChanged()">
                                    <mat-option *ngFor="let item of QMSConst.TICKET_VENDORS" [value]="item">{{item}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="col-md-3">
                        </div>

                        <div class="col-md-2">
                            <button mat-raised-button (click)="network.goToNewTicketPage()">New Ticket</button>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <mat-checkbox [(ngModel)]="showWishlistItems" color="primary" (ngModelChange)="queryMenuChanged()">Show Wishlist & Deferred Items</mat-checkbox>
                        </div>
                    </div>

                    <div *ngIf="(dataSource.data == undefined || dataSource.data.length == 0) && !isLoading">
                        {{noTicketsMessage}}
                    </div>

                    <div class="row" *ngIf="isLoading">
                        <mat-spinner [diameter]="progressSpinnerSize" style="margin: 15px;"></mat-spinner>
                    </div>

                        <!-- BUG FIX: mat-paginator breaks if it's put inside an *ngIf block that is hidden and shown. 
                             So instead we use [hidden]. -->
                    <div [hidden]="dataSource.data == undefined || dataSource.data.length == 0" class="mat-elevation-z8">
                        <div class="row" style="margin-left:20px">
    
                                <!-- Show Columns multiselect -->
                            <div class="col-md-2">
                                <mat-form-field appearance="legacy">
                                    <mat-select [(value)]="displayedColumns" multiple>
                                        <mat-select-trigger>Show</mat-select-trigger>
                                        <mat-option *ngFor="let item of allColumns" [value]="item">{{item}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div class="col-md-1">
                            </div>

                            <!-- Search -->
                            <div class="col-md-4">
                                <mat-form-field>
                                    <mat-label>Search</mat-label>
                                    <input matInput (keyup)="applyFilter($event)" placeholder="" #input autocomplete="off">
                                </mat-form-field>
                            </div>

                                <!-- Paginator -->
                            <mat-paginator style="float: right; margin-right:20px;" [pageSizeOptions]="[5, 10, 20, 30, 40, 50, 100]"></mat-paginator>
                        </div>

                        <table mat-table [dataSource]="dataSource" matSort>

                            <!-- Checkbox Column -->
                        <!--
                            <ng-container matColumnDef="select">
                                <th mat-header-cell *matHeaderCellDef style="width: 4%;">
                                    <mat-checkbox (change)="$event ? masterToggle() : null; checkboxClicked();"
                                                [checked]="selection.hasValue() && isAllSelected()"
                                                [indeterminate]="selection.hasValue() && !isAllSelected()">
                                    </mat-checkbox>
                                </th>
                                <td mat-cell *matCellDef="let row">
                                    <mat-checkbox (click)="$event.stopPropagation();"
                                                (change)="$event ? selection.toggle(row) : null; checkboxClicked();"
                                                [checked]="selection.isSelected(row)">
                                    </mat-checkbox>
                                </td>
                            </ng-container>
                        -->
                            
                            <!-- Flagged Column -->
                            <ng-container matColumnDef="flagged">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 3%;"><i class="fa fa-flag" aria-hidden="true" ></i></th>
                                <td mat-cell *matCellDef="let row">
                                    <i class="fa fa-flag" *ngIf="row.flagged" aria-hidden="true" style="color:red"></i>
                                </td>
                            </ng-container>

                            <!-- ID Column -->
                            <ng-container matColumnDef="id">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 9%;"> ID </th>
                                <!-- For now this is an old angular.js style link. Switch from href to routerLink when upgrading to a Angular link! And add: routerLinkActive="active" -->
                                <td mat-cell *matCellDef="let row"> <a href="/#/ticket/{{row.id}}">{{row.id}}</a> </td>
                            </ng-container>

                            <!-- Status Column -->
                            <ng-container matColumnDef="status">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 9%;"> Status </th>
                                <td mat-cell *matCellDef="let row"> {{QMSConst.TicketStatusCodeToString(row.status)}} </td>
                            </ng-container>

                            <!-- Vendor Column -->
                            <ng-container matColumnDef="vendor">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 9%;"> Vendor </th>
                                <td mat-cell *matCellDef="let row"> {{row.vendor}} </td>
                            </ng-container>

                            <!-- Branches Column -->
                            <ng-container matColumnDef="branches">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 9%;"> Branches </th>
                                <td mat-cell *matCellDef="let row"> {{row.branches}} </td>
                            </ng-container>

                            <!-- Severity Column -->
                            <ng-container matColumnDef="severity">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 9%;"> Severity </th>
                                <td mat-cell *matCellDef="let row"> {{row.severity}} </td>
                            </ng-container>
                            
                            <!-- App Column -->
                            <ng-container matColumnDef="app">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 9%;"> App </th>
                                <td mat-cell *matCellDef="let row"> {{row.app}} </td>
                            </ng-container>
                
                            <!-- Owner Column -->
                            <ng-container matColumnDef="owner">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 9%;"> Owner </th>
                                <td mat-cell *matCellDef="let row"> {{row.owner}} </td>
                            </ng-container>
                            
                            <!-- Summary Column -->
                            <ng-container matColumnDef="summary">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 70%;"> Summary </th>
                                <td mat-cell *matCellDef="let row"> {{row.summary}} </td>
                            </ng-container>
                
                    
                        <!-- Color Column
                        <ng-container matColumnDef="color">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Color </th>
                            <td mat-cell *matCellDef="let row" [style.color]="row.color"> {{row.color}} </td>
                        </ng-container>
                        -->
                    
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                            <!-- Row shown when there is no data matching the filter. -->
                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                            </tr>

                        </table>
                    </div>

                    <p style="color:red"><b>{{httpErrorMsg}}</b></p>
                </div>
            </div>
        </div>
    </div>
</div>