<h2 mat-dialog-title>{{data.title}}</h2>

<mat-dialog-content class="mat-typography">

    <div class="row" *ngIf="data.statusNew == QMSConst.JOB_STATUS_TESTING">
        <div class="col-md-12">
            This job will appear in a 'Jobs ready for Testing' panel on all QA users Home pages. Whoever starts a Test Worksheet first will claim ownership of the job.
        </div>
    </div>

    <div class="row" *ngIf="data.statusNew == QMSConst.JOB_STATUS_CODE_REVIEW">
        <div class="col-md-12">
            <b>Please select the user you wish to perform the code review:</b>
        </div>
    </div>

    <div class="row">
        <div class="col-md-5" *ngIf="data.statusNew != QMSConst.JOB_STATUS_TESTING && data.statusNew != QMSConst.JOB_STATUS_CLOSED">
            <mat-form-field appearance="outline">
                <mat-label>Change Owner to:</mat-label>
                <mat-select [(value)]="data.owner">
                    <mat-option *ngFor="let user of data.users" [value]="user.username">{{user.fullname}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="col-md-5" *ngIf="data.showStatusMenu">
            <mat-form-field appearance="outline">
                <mat-label>Change Status to:</mat-label>
                <mat-select [(value)]="data.statusNew">
                    <mat-option *ngFor="let item of data.statusMenuItems" [value]="item.id">{{item.type}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <p *ngIf="data.warning"><b>{{data.warning}}</b></p>
    <p>{{data.body}}</p>

   <!-- <p>
        <mat-button-toggle-group #passFail="matButtonToggleGroup">
            <mat-button-toggle value="Pass" color="primary">Pass</mat-button-toggle>
            <mat-button-toggle value="Fail" color="warn">Fail</mat-button-toggle>
        </mat-button-toggle-group>
    </p>-->

    <mat-form-field appearance="outline" style="width:100%">
        <mat-label>Comments</mat-label>
        <textarea [(ngModel)]='data.comments' matInput style="min-height:100px;" cdkTextareaAutosize></textarea>
    </mat-form-field>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="{clickedOk: false}">Cancel</button>
    <button mat-button [mat-dialog-close]="{clickedOk: true}" [disabled]="data.owner == '' && data.statusNew != QMSConst.JOB_STATUS_CLOSED" cdkFocusInitial color="primary">Okay</button>
</mat-dialog-actions>