import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
// import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';

import { AuthTokens } from '../../app.services';
import { QMSNetwork } from '../../qmsnetwork.service';
import { QMSConst, JobStatus } from '../../qms.classes';


@Component({
  selector: 'tickets-page',
  templateUrl: './tickets.html',
  styleUrls: ['./tickets.css']
})
export class TicketsPage implements AfterViewInit, OnInit {
        // @ts-ignore
    @ViewChild(MatPaginator) paginator: MatPaginator;
        // @ts-ignore
    @ViewChild(MatSort) sort: MatSort;

        // @ts-ignore
    @Input() ishomepage: boolean;       // true, false, or undefined. If true, show only open tickets for the current user

        // Don't forget! When adding new columns to the html, you must add them here too!
        // BUG FIX: For sorting to work properly, each name here must match the corresponding property in the object data
    allColumns: string[] = ['flagged', 'id', 'app', 'status', 'owner', 'vendor', 'branches', 'severity', 'summary', ]; // This ALSO defines the order Angular will display them in?
    displayedColumns: string[] = ['flagged', 'id', 'app', 'owner', 'status', 'summary'];
    dataSource: MatTableDataSource<Ticket>;

    //selection = new SelectionModel<Ticket>(true, []);
    //numSelected: number = 0;

    readonly QMSConst = QMSConst;
    readonly progressSpinnerSize = 30;

        // Query terms
    showWishlistItems: boolean = false;
    queryOwner: string = "";            // username or "" for all users
    queryVendor: string = "";           // vendor name or "" (or 'all') for all vendors
    queryStatuses: number[] = [QMSConst.TICKET_STATUS_OPEN];    // By default, search for tickets that are 'Open' (1)

    users:CRMUser[] = [];
    isLoading: boolean = true;
    noTicketsMessage:string = "There are no tickets matching this search criteria.";
    httpErrorMsg:string = "";

    constructor(public network: QMSNetwork, private authTokens: AuthTokens, private titleService: Title) {
        console.log("//-----------------------------------------------");
        console.log("// Tickets page constructor entered");
        console.log("//-----------------------------------------------");
        this.titleService.setTitle( "Tickets" );

        this.dataSource = <any>new MatTableDataSource([]);

        this.network.getUsersWithPermission("TICKETS")
            .subscribe( 
                res => {
                    this.users = res;
                    console.log("Got users with TICKET permissions: ", res);
                },
                err => { this.httpErrorMsg = err.message}
            //    , () => console.log('getUsersWithPermission() request completed.')
            );
    }

    ngOnInit() {
        console.log("tickets: ishomepage: ", this.ishomepage, typeof this.ishomepage);
            // When this component is used from the user's Home page, show only open tickets for the logged-in user
        if (this.ishomepage) {
            this.queryOwner = this.authTokens.getUser().username;
            this.queryStatuses = [QMSConst.TICKET_STATUS_OPEN];   // Open tickets only
            this.noTicketsMessage = "You have no open tickets.";
        }

        this.loadTickets();
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.paginator.pageSize = 10;
        this.dataSource.sort = this.sort;
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator)
            this.dataSource.paginator.firstPage();
    }

    statusMenuItemClicked(event:any) {
        console.log(event);
        if (this.queryStatuses.includes(<any>"Clear"))
            this.queryStatuses = [];
    }

    queryMenuChanged() {
        this.loadTickets();
    }

    loadTickets() {
        this.isLoading = true;
        this.dataSource.data = [];

        console.log("Getting tickets by owner: ", this.queryOwner);

            // Include Wishlist items?
        let queryStatuses = JSON.parse( JSON.stringify(this.queryStatuses) );
        if (this.showWishlistItems)
        {
            queryStatuses.push( QMSConst.TICKET_STATUS_WISHLIST );
            queryStatuses.push( QMSConst.TICKET_STATUS_FIX_NEXT_MINOR );
            queryStatuses.push( QMSConst.TICKET_STATUS_DEFER_NEXT_MAJOR );
        }


        this.network.getTickets(this.queryOwner, queryStatuses, this.queryVendor)
            .subscribe(
                res => {
                    console.log("Get tickets API result: ", res);

                    if (res)
                        this.dataSource.data = res;
                    else
                        this.httpErrorMsg = "No tickets found. Please make sure you are logged in.";


                //    this.numSelected = 0;
                //    this.selection.clear();
                    this.isLoading = false;
                //    this.enableChangeStatusButton = true;
                },
                err => { this.httpErrorMsg = err.message}
         //     , () => console.log('getTickets() request completed.')
            );
    }

    
    //--------------------------------------------------------------------------------------------
    // Checkbox selection handling
    //--------------------------------------------------------------------------------------------

/*
    isAllSelected() {
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return numSelected === numRows;
    }

        // Selects all rows if they are not all selected; otherwise clear selection.
    masterToggle() {
      this.isAllSelected() ?
          this.selection.clear() :
          this.dataSource.data.forEach(row => this.selection.select(row));
    }

    checkboxClicked()
    {
        this.numSelected = this.selection.selected.length;
        this.enableChangeStatusButton = true;
        this.printSelected();
    }

    printSelected()
    {
        console.log("Currently selected jobs: ");
        for (const job of this.selection.selected)
            console.log(job);
    }
*/
}


