import {Component, Inject} from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';

/* Usage:

    import { ConfirmDialog } from 'src/app/dialogs/confirm/confirm'

    const dialogRef = this.dialog.open(ConfirmDialog, {data: {title: "Save changes", body:"Please enter comments and then save."}}
        );

    dialogRef.afterClosed().subscribe( (result:boolean) => {
            // result: true (Okay), false (cancel), or undefined (clicked outside dialog to dismiss)
        console.log(`Dialog result: ${result}`);
    });
*/

export interface ConfirmDialogData {
    title: string;
    body: string;
}

@Component({
    selector: 'confirm-dialog',
    templateUrl: 'confirm.html',
})

export class ConfirmDialog {
    constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData) {}
    }

