import { Injectable } from '@angular/core';

// User Preferences backed by window.localStorage (centralized as a service in root)
@Injectable({providedIn: 'root'})
export class LocalUserPreferences
{
    store: Storage;
    readonly keyPrefix: string = 'user-pref-';

    constructor() {
        this.store = window.localStorage;
    }

    // Page size for JobsPage
    getJobsPageSize(paneltype: string): number {
        let key = this.keyPrefix + 'jobs-page-size' + (paneltype ? ('-' + paneltype) : '');
        let value = Number(this.store.getItem(key));
        return isNaN(value) ? 0 : value;
    }

    // Sets page size for JobsPage
    setJobsPageSize(paneltype: string, size: number) {
        let key = this.keyPrefix + 'jobs-page-size' + (paneltype ? ('-' + paneltype) : '');
        this.store.setItem(key, '' + size);
    }
}


// Modern Angular Service version of old angular.js-based AuthTokenFactory
@Injectable({
    providedIn: 'root'
})
export class AuthTokens
{
    store: Storage;
    readonly key: string = 'auth-token';

        // Previously injected in angular.js: $window, $rootScope, $location
    constructor()
    {
    //    this.$window = window;
    //    this.$rootScope = $rootScope;
     //   this.$location = $location;

        this.store = window.localStorage; // $window.localStorage;

    //    console.log("NEW AuthTokenFactory instantiated ");
    }

    getToken():string|null
    {
        let token:string|null = this.store.getItem(this.key);
    //    console.log("AuthTokenFactory::getToken() returning token: ", token);
        return token;
    }

    setToken( token:string|null = null ) {
    //    console.log("AuthTokenFactory::setToken() about to set token: ", token);
        // Don't override token if user is older
        let user = this.getUser();
        if (token && this.isUserValid(user) && typeof user.timestamp !== 'undefined') {

                // Revised by Vern 4/7/2021 after seeing that the previous code was wrong once I strongly typed CRMUser in TypeScript. UNTESTED.
            var older = parseInt(user.timestamp.$date.$numberLong);
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace('-', '+').replace('_', '/');
            var happyToken = JSON.parse( window.atob(base64) );
            var tokenUser:CRMUser = happyToken.user;
            var current = parseInt(tokenUser.timestamp.$date.$numberLong);
            if (current < older) 
            {
                console.log("  NEW AuthTokenFactory::setToken ABORTING: token is older than existing user's timestamp");
                return;
            }
        }

        if (token) {
        //    console.log("  NEW AuthTokenFactor::setToken() is storing token: ", token);
            this.store.setItem(this.key, token);
        } else {
        //    console.log("  NEW AuthTokenFactor::setToken() is REMOVING token!!!");
            this.store.removeItem(this.key);
        }

            // TODO: navbar.js's init() listens for this. 
    //    this.$rootScope.$broadcast("TokenChanged");
    }

    getUser():CRMUser {
        var token = this.getToken();
        if (!token)
        {
        //    console.log("AuthTokenFactory: getUser() does not have a valid token. Aborting.");
            return <CRMUser>{valid: false};
        }
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace('-', '+').replace('_', '/');
        var happyToken = JSON.parse( window.atob(base64) );

        let user:CRMUser = happyToken.user;
        if (typeof user === 'undefined') {
        //    console.log("NEW AuthTokenFactory: Error getting user information (unable to parse token)");
            user = <CRMUser>{valid:false};
        }
        else
            user.valid = true;

     //   console.log("NEW AuthTokenFactory getting user: ", user);

        return user;   
    }

        // Should work, but honestly JavaScript should never be accessing the MongoID
/*
    getUserMongoID()
    {
        let user = this.getUser();
        if (user && user._id && user._id.$oid)
            return user._id.$oid;
        else
            return "";
    }
*/

    isUserValid( user:CRMUser )
    {
        return (user.valid !== false);
    }

    logout()
    {
    //    console.log("NEW AuthTokenFactory::logout() called.");

            // TODO: Fix me!!!
        window.location = <any>"/#/login";
    //    this.$location.path("/login");

        this.setToken(null);

        // TO DO : Close all open dialogs here.
    }
}



import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse
} from '@angular/common/http';
import {map} from 'rxjs/operators';

//import { Observable } from 'rxjs';

// import { AuthTokens } from './app.services';

@Injectable()
export class AuthInterceptor implements HttpInterceptor
{
    constructor(private auth: AuthTokens) {}

    intercept(req: HttpRequest<any>, next: HttpHandler)
    {
     //   console.log("NEW AuthInterceptor entered.");

        // Get the auth token from the service.
        const authToken = this.auth.getToken();
        if (authToken)
        {
            // Clone the request and replace the original headers with
            // cloned headers, updated with the authorization.
            const authReq = req.clone({
                headers: req.headers.set('Authorization', <string>authToken)
            });

            /*      // Here's how we used to do it for angular.js:
                config.headers = config.headers || {};
                config.headers.Authorization = 'Bearer ' + token;
                console.log("Adding token to outgoing request: ", config);
            }
            */

        //    console.log("NEW AuthInterceptor adding token to HTTP request!");

                    // send cloned request with header to the next handler.
        //    return next.handle(authReq);
            req = authReq;
        }

            // No changes made
     //   return next.handle(req);

        return next.handle(req).pipe(map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                let modifiedEvent = this.handleResponse(event);
                if (modifiedEvent)
                    event = modifiedEvent;
            }
            return event;
        }));
    }

    handleResponse(response: HttpResponse<any>):HttpResponse<any>|null
    {
    //    console.log("NEW: intercepted HTTP response! ", response);
        if (response.body)
        {
            if (response.body.token)
            {
                let newToken = response.body.token;
            //    console.log("INTERCEPTED token from response!", newToken);
                this.auth.setToken(newToken);
                // response = response.clone({body: this.modifyBody(response.body)});
            }
            
            if ( response.body.data && response.body.data.alert)
            {
            //    console.log("AuthInterceptor::handleResponse() got an alert for response: ", response);

                if (response.body.data.message)
                    console.log("AuthInterceptor: Server return an ALERT: (" + response.body.data.alert + "):", response.body.data.message);

                    // Special code for expired token (log user out)
                if (response.body.data.alert === 10)
                    this.auth.logout();
            }
        }

        return null;
    }
}
