<div class="col-md-12">
    <div class="row">
        <div class="col-sm-12">
            <div class="panel panel-primary">
                <div class="panel-heading">
                    <div class="row">
                        <div class="col-md-5">
                            <h3 class="panel-title">
                                Test Summary Report
                             </h3>
                        </div>
                    </div>
                </div>
                <div class="panel-body">
                     

                    <h2>Test Summary Report:
                        <div class="row">
                            <div class="col-md-2">
                                <mat-form-field appearance="outline">
                                    <mat-label>Release</mat-label>
                                    <mat-select [(value)]="selectedItem" (selectionChange)="reload()">
                                        <mat-option *ngFor="let release of QMSConst.RELEASES" [value]="release">{{release.name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4">
                                <div *ngIf="isLoading">
                                    <span style="display: inline-block; height: 80px;">
                                        <mat-spinner [diameter]="progressSpinnerSize" style="margin: 15px;"></mat-spinner>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </h2>
                    
                    <h3>All changes to Branch: {{selectedItem.branch}} between CL {{selectedItem.start}} and {{selectedItem.end}} 
                        <small>Total Changes: {{jobs.length}} ({{numNoTests}})</small>
                    </h3>

                                        
                    <table class="table table-hover">
                        <thead>
                            <tr><th>Job ID</th><th>Status</th><th>Summary</th><th>Latest {{selectedItem.branch}} CL</th><th>Fixes Ticket</th></tr>
                        </thead>
                        <tbody *ngFor="let job of jobs"> <!-- let job of Utils.sortBy(jobs, 'jobid') -->
                            <tr>
                                <td><a href='/#/job/{{job.jobid}}'>{{job.jobid}}</a></td>
                                <td [ngStyle]="getStyleForJobStatus(job.statusNew)">{{getStatus(job.statusNew)}} <span *ngIf="job.owner.length > 0">({{job.owner}})</span></td>
                                <td>{{job.summary}}</td>
                                <td nowrap style="width: 180px">
                                    <div class="job" *ngFor="let branch of Utils.sortBy(job.tw_branches, 'id')"> {{branch.maxcl}}</div>
                                </td>
                                <td>
                                    <span *ngIf="job.ticketid!='None'">Ticket: {{job.ticketid}}</span>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="5">
                                    <span *ngIf="job.twid && job.twid.length > 0">Test Worksheet: </span><a href="/#/tw/{{job.jobid}}/{{job.twid}}">{{job.twid}}</a>
                                    <span *ngIf="!hasTest(job) && job.tests.length > 0">Job Still Open: Tests not yet performed - planned tests below:</span>
                                    <table style="width: 100%" class="table table-bordered table-condensed" *ngIf="job.tests.length != 0">    
                                        <tbody *ngIf="hasTest(job)">
                                            <tr *ngFor="let twTest of job.tw_branches[0].tw.tests" [ngStyle]="getStyleForTestResult(twTest.result)">
                                                <td style="width: 70"><u>Test ID:</u> {{twTest.testid}}</td>
                                                <td><u>Description:</u> {{twTest.description}}</td>
                                                <td style="width: 200px"><u>Expected Result:</u> {{twTest.expected_result}}</td>
                                                <td style="width: 100px"><u>Actual Result:</u> <strong>{{twTest.result}}</strong></td>
                                            </tr>
                                        </tbody>

                                        <tbody *ngIf="!hasTest(job)">
                                            <tr *ngFor="let test of job.tests"  class="error" style='background-color:light'>
                                                <td style="width: 70px"><u>Test ID:</u> {{test.testid}}</td>
                                                <td><u>Description:</u> {{test.description}}</td>
                                                <td style="width: 200px"><u>Expected Result:</u> {{test.expected_result}}</td>
                                            </tr>
                                        </tbody>          
                                    </table>
                                    
                                    <table style="width: 100%" class="table table-bordered table-condensed" *ngIf="job.tests.length==0"> 
                                        <tr class="info">
                                            <td style="width: 40%">No Formal Testing Performed. <div style="float:right">Justification:</div></td>
                                            <td><strong>{{job.no_test_reason}}</strong></td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>

                        </tbody>
                    </table>


                    <p style="color:red"><b>{{httpErrorMsg}}</b></p>
                </div>
            </div>
        </div>
    </div>
</div>
