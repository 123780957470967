import { AfterViewInit, OnInit, Component, Input, ViewChild, ɵdetectChanges } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FormControl } from '@angular/forms';
//import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { MatSelectChange } from '@angular/material/select';
import { MatDialog } from '@angular/material/dialog';
import { CommentDialog } from 'src/app/dialogs/comment/comment'
import { MessageDialog } from 'src/app/dialogs/message/message'
import { ConfirmDialog } from 'src/app/dialogs/confirm/confirm'
import { SelectDialog } from 'src/app/dialogs/select/select'
import { ReviewJobDialog } from 'src/app/dialogs/reviewjob/reviewJob'

import { QMSNetwork } from '../../qmsnetwork.service';
import { AuthTokens } from '../../app.services';
import { QMSUtils, QMSConst } from '../../qms.classes';
import { Utils } from '../../utils';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';


@Component({
  selector: 'testsummary-page',
  templateUrl: './testsummary.html',
  styleUrls: ['./testsummary.css']
})
export class TestSummaryPage implements OnInit, AfterViewInit {

    readonly QMSConst = QMSConst;
    readonly Utils = Utils;

    readonly progressSpinnerSize = 30;

    selectedItem:any = {};    // Selected QMSConst.RELEASES item
    jobs:JobExtended[] = [];
    numNoTests:number = 0;

    isLoading:boolean = false;
    httpErrorMsg:string = "";               // Set to an HTTP error message if various HTTP methods fail

        // Later: use this once we're a fully modern Angular site:  (private route: ActivatedRoute)
    constructor(private network: QMSNetwork, private authTokens:AuthTokens, public dialog: MatDialog, private titleService: Title) {
        console.log("//-----------------------------------------------");
        console.log("// Test Summary Report page constructor entered");
        console.log("//-----------------------------------------------");

        this.titleService.setTitle( "Test Summary Report" );
    }

    ngOnInit() {

    }

    ngAfterViewInit() {
        
    }

    reload() {
        console.log(this.selectedItem);

        this.jobs = [];
        this.isLoading = true;

        this.network.getJobsExtended( this.selectedItem.branch, this.selectedItem.start, this.selectedItem.end )
                .subscribe( res => {
                    if (res)
                    {
                        this.jobs = res;
                        this.isLoading = false;

                        console.log("Got extended jobs: ", this.jobs);
    
                        for (let n=0; n < this.jobs.length; n++)
                            QMSUtils.fixJobSchema( this.jobs[n] );
                        
                        this.computeNumNoTests();
                    }
                },
                err => { this.httpErrorMsg = err.message}
            , () => { this.isLoading = false;}
            );
    }

    hasTest( job:JobExtended ):boolean {
        return (typeof job.tw_branches[0].tw === 'object');
    }

    computeNumNoTests() {
        this.numNoTests=0;
        for (let i=0; i < this.jobs.length; i++) {
            if (this.hasTest(this.jobs[i]))
                this.numNoTests++;
        }
    }

    getStatus( status:number ) {
        return QMSConst.JobStatusNumToString[status];
    }

        // 'Pass', 'Fail', and 'Notest' should show up in different colors
    getStyleForTestResult( result:string|undefined ):any
    {
        if (result == "Pass")
            return {'background-color': '#dff0d8'}; // light green
        else if (result == "Fail")
            return {'background-color': '#ffb8b8'}; // light red
        else
            return {'background-color': '#fff6c7'}; // light yellow (Not Tested)
    }

    getStyleForJobStatus( statusNew:number ):any
    {
        if (statusNew == QMSConst.JOB_STATUS_CLOSED)
            return {'background-color': '#dff0d8'}; // light green 
        else
            return {'background-color': '#ffb8b8'}; // light red
    }

        // Unused! And outdated!
/*
    getClass( job:Job ):string {
        if (job.status != 4)
            return "info";
        return "";
    }*/
}

