import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators'; 
import { Observable, throwError } from 'rxjs'; 
import { Utils } from './utils';
import { QMSConst } from './qms.classes';

// These HTTP methods return rxjs Observables. Learn about Observables here:
// https://blog.angular-university.io/rxjs-error-handling/


@Injectable({
    providedIn: 'root'
})
export class QMSNetwork 
{
    static readonly API_URL = "/api";

    constructor(private httpClient: HttpClient, private router: Router)  { 

    }

    // ---------------------------------------------------------------------
    // Routes
    // ---------------------------------------------------------------------

    public goToNewTicketPage() {
        this.router.navigateByUrl("/#/ticket/new" );
    }

    // ---------------------------------------------------------------------
    // Tickets
    // ---------------------------------------------------------------------

    public getTicket( ticketid: string ):Observable<Ticket> {
        return this.get( "/ticket/" + ticketid );
    }

        /** Returns a new unique ticketID for a new ticket */
    public getNewTicketID():Observable<QMSHTTPSaveResponse> {
        return this.get( "/ticketnewid" );
    }

    public saveTicket( ticket:Ticket, action:string, comments:string ):Observable<QMSHTTPSaveResponse> {
        return this.post( "/ticket", {record:ticket, action, comments} );
    }

    public createJobFromTicket( ticket:Ticket ):Observable<QMSHTTPSaveResponse> {
        return this.post( "/newjobfromticket", {record: ticket} );
    }

        // @owner: Pass a username or "" for tickets by all users
        // @statuses: pass an array of ints to restrict results to tickets with certain statuses, i.e. [1,3,4] Empty array [] for all.
        // @vendor: "" or name of a vendor: QMSConst.TICKET_VENDORS [CSMC Direct", "CSMC (internal)","Siemens","GE","Philips","Spectrum","Digirad","Mirada","Thinksys"]
    public getTickets( owner:string, statuses:Array<number>, vendor:string ):Observable<Array<Ticket>> {
        if (owner === "")
            owner = "all";
        if (vendor === "")
            vendor = "all";
        let statusStr = "all";
        if (statuses.length > 0)
            statusStr = statuses.join(",");  // Convert array to string
        return this.get("/tickets/" + owner + "/" + statusStr + "/" + vendor);
    }

    // ---------------------------------------------------------------------
    // Jobs
    // ---------------------------------------------------------------------

    public getJob( jobid: string ):Observable<Job> {
        return this.get( "/job/" + jobid );
    }

    public saveJob( job:Job, action:string, comments:string ):Observable<QMSHTTPSaveResponse> {
        return this.post( "/job", {job, action, comments} );
    }

    public removeReview( jobid:string, reviewType:number, reviewUsername:string ):Observable<QMSHTTPSaveResponse> {
        return this.post( "/job/removereview", {jobid: jobid, type: reviewType, username: reviewUsername} );
    }

        // PHP side: $result = Jobs::reviewJob( $data['jobid'], $data['type'], $data['status'], $data['comments'], $data['changeStatus'], $data['cl'] );
        // reviewType: the current job status code that is being reviewed
        // @newStatus: the status code that will become the job's current status once this review is submitted
    public reviewJob( jobid:string, reviewType:number, status:"Pass"|"Fail", comments:string, newStatus:number, cl:any,
                      prevUsername:string, assignToUsername:string):Observable<QMSHTTPSaveResponse>
    {
        return this.post( "/job/reviewjob", {jobid, type: reviewType, status, comments, newStatus, cl, prevUsername, assignToUsername} );
    }

        // Returns only jobs that aren't closed. Works properly with both old QMS and new QMS job records (status and statusNew)
        // @owner: Pass a username or "none" for jobs with no owner, or "any" for jobs by all users
    public getOpenJobs( ownerFieldName:"owner"|"developerOwner", owner:string ):Observable<Array<Job>> {
        return this.get("/openjobs/" + ownerFieldName + "/" + owner);
    }

        // @owner: Pass a username or "none" for jobs with no owner, or "any" for jobs by all users
        // @statuses: empty array [] for all. pass an array of ints to restrict results to jobs with certain statuses, i.e. [10,12,18] See qms.classes JobStatus class for options.
        //    ***WARNING*** !!! If you pass status strings, old QMS jobs WILL NOT show up, since none of the old jobs have the new codes. Only jobs created in the 'new' QMS will show up.
    public getJobs( owner:string, statuses:Array<number> ):Observable<Array<Job>> {
        let statusStr = "all";
        if (statuses.length > 0)
            statusStr = statuses.join(",");  // Convert array to string
        return this.get("/jobs/" + owner + "/" + statusStr);
    }

        /** Returns an extended list of jobs for the Test Summary Report  */
    public getJobsExtended( branch:any, startCL:number, endCL:number ):Observable<Array<JobExtended>>
    {
        return this.get("/jobextended/" + branch + "/" + startCL + "/" + endCL);
    }

    public setJobStatus( jobid:string, status:number ):Observable<QMSHTTPSaveResponse> {
        return this.post( "/job/setstatus", {jobid, status} );
    }

    public setJobStatusAndOwner( jobid:string, status:number, ownerUsername:string, prevOwnerUsername:string ):Observable<QMSHTTPSaveResponse> {
        console.log("setJobStatusAndOwner sending owner username: ", ownerUsername, " for jobid: ", jobid);
        return this.post( "/job/setstatusowner", {jobid, status, owner:ownerUsername, prevOwner:prevOwnerUsername } );
    }

    public closeP4Job( jobid: string ):Observable<Job> {
        return this.get( "/closep4job/" + jobid );
    }

        // owner: P4 username of job owner
        // type: "Bugfix"|"Major"|"Minor"|"Other"|"---"
        // ticketid: optional
    public createP4Job( owner: string, type: string, summary: string, requirements: string, ticketid: string = "" ):Observable<Job> {
        return this.post( "/createp4job", {owner, type, summary, requirements, ticketid} );
    }

    // ---------------------------------------------------------------------
    // Test Worksheets (related to a job)
    // ---------------------------------------------------------------------

        /** @twid - the MongoDB _id of the Test Worksheet to load  */
    public getTestWorksheet( twid: string ):Observable<TestWorksheet> {
        return this.get( "/tw/" + twid );
    }

        /** Return all test worksheets associated with a jobid  */
    public getTestWorksheets( jobid: string ):Observable<TestWorksheet[]> {
        return this.get( "/tws/" + jobid );
    }

    public saveTestWorksheet( tw:TestWorksheet ):Observable<QMSHTTPSaveResponse> {
        return this.post( "/tw", {record:tw} );
    }

    // ---------------------------------------------------------------------
    // Changes (for jobs)
    // ---------------------------------------------------------------------

    public getJobChanges( jobid: string ):Observable<any> {
        return this.get( "/jobchanges/" + jobid );
    }

    // ---------------------------------------------------------------------
    // Users
    // ---------------------------------------------------------------------

    public getUsersForGroup( group: string ):Observable<Array<CRMUser>> {
        return this.get( '/user/group/' + encodeURIComponent(group)  );
    }

        // Get all users that have a specific permissions, such as "CUSTOMER" or "TICKETS"
    public getUsersWithPermission( permission: string ):Observable<Array<CRMUser>> {
        return this.get( '/user/permission/' + encodeURIComponent(permission)  );
    }

    // ---------------------------------------------------------------------
    // Misc
    // ---------------------------------------------------------------------

        // @lists: "all" or list names separated by "|" i.e. (apps|releases|suites)
        // res: an object with a key for each requested list.
    public getLists( lists: string ):Observable<any> {
        return this.get( '/lists/' + encodeURIComponent(lists)  );
    }

        // Useful if having trouble with the backend and want an easy endpoint to test with
    public doPOSTTest( jobid:string, status:number ):Observable<any> {
        return this.post( "/job/test", {jobid, status} );
    }

    public getDBInfo():Observable<DBInfo> {
        return this.get( "/info/" );
    }

    public sendEmail( emailAddress:string, subject:string, body:string, bcc:string[] = [] ):Observable<QMSHTTPSaveResponse> {
        return this.post( "/misc/sendemail", {emailAddress, subject, body, bcc} );
    }
    

    // -----------------------------------------------------------------------------------------------------

    public getAPIUrl() {
        return QMSNetwork.API_URL;
    }

    public post( url:string, body:any ):Observable<any> {
        return this.httpClient.post(QMSNetwork.API_URL + url, body)
            .pipe(
                map(result => (<any>result).data),
                catchError(this.handleError)
            );
    }

    public get( url:string ):Observable<any> {
        return this.httpClient.get(QMSNetwork.API_URL + url)
            .pipe(
                    // All HTTP responses contain a "data" and a "token" field. data contains the response our callers care about.
                    // Here we map result to result.data, so that the object is returned, and the caller doesn't have to read a 'data' field first.
                map(result => (<any>result).data),
                catchError(this.handleError)
            );
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          Utils.logError('QMSNetwork caught an HTTP error:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong.
          Utils.logError(
            `QMSNetwork caught an HTTP error. Backend returned code ${error.status}, `, 
            "Body was:", error.error);
        }
        // Return an observable with a user-facing error message.
        return throwError(error);
      }
}
