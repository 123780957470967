import { AfterViewInit, OnInit, Component, Input, ViewChild, ɵdetectChanges } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FormControl } from '@angular/forms';
//import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Router } from '@angular/router';

import { MatSelectChange } from '@angular/material/select';
import { MatDialog } from '@angular/material/dialog';
import { CommentDialog } from 'src/app/dialogs/comment/comment'
import { MessageDialog } from 'src/app/dialogs/message/message'
import { ConfirmDialog } from 'src/app/dialogs/confirm/confirm'
import { SelectDialog } from 'src/app/dialogs/select/select'
import { ReviewJobDialog } from 'src/app/dialogs/reviewjob/reviewJob'

import { QMSNetwork } from '../../qmsnetwork.service';
import { AuthTokens } from '../../app.services';
import { QMSConst, QMSUtils } from '../../qms.classes';
import { Utils } from '../../utils';


@Component({
  selector: 'ticket-page',
  templateUrl: './ticket.html',
  styleUrls: ['./ticket.css']
})
export class TicketPage implements OnInit {

        // @ts-ignore
    @Input() ticketid: string; // Passed in from a ng1 wrapper: see ng1/ng2/ticketPageWrapper.js and ng1/setup.js

    readonly QMSConst = QMSConst;
    readonly progressSpinnerSize = 30;
    readonly minusOne = -1;     // So we can set an HTML element's value to -1, not the string "-1"

    ticket:Ticket = <Ticket>{};
    prevTicket:Ticket = <Ticket>{};
    changedFields:Array<string> = [];
    changedSentence: string = "";

    newComment: string = "";
    statusMsg:string = "";              // Set when the Change Status/Owner method succeeds or fails, or when the job loaded has an owner that isn't in the menu
    isDisabled = false;

    currentDatabase:string = "";
    users:CRMUser[] = [];             // Users part of Engineering group. Used to populate Owner menu, as well as by Reviews/Approvals system.
    apps:any = [];

    isChangingStatus:boolean = false;   // Show spinner until we hear back from the server ocne user clicks Change Owner/Status button
    isSaving:boolean = false;           // Used to instantly disable Save/Change buttons once they've been pressed until job has been saved & reloaded
    saveResult:string = "";             // shows "Success" to user after they click Save if save to server was successful

    isLoading: boolean = true;
    httpErrorMsg:string = "";

    constructor(private network: QMSNetwork, private authTokens: AuthTokens, public dialog: MatDialog, private router: Router, private titleService: Title) {
        console.log("//-----------------------------------------------");
        console.log("// Ticket page constructor entered");
        console.log("//-----------------------------------------------");

        this.network.getDBInfo().subscribe(
            res => {
                console.log("getDBInfo got response: ", res);
                if (res.crmDBName != "qms" && res.designDBName != "design")
                    this.currentDatabase = "Using development databases. (CRM: " + res.crmDBName + ".  Design: " + res.designDBName + ")";
            },
            err => { this.httpErrorMsg = err.message}
       //     , () => console.log('getDBInfo() request completed.')
        );
        
        this.network.getUsersWithPermission("TICKETS")
            .subscribe( 
                res => {
                    this.users = res;
                    console.log("Got users with TICKETS permission: ", res);
                },
                err => { this.httpErrorMsg = err.message}
            //    , () => console.log('getUsersWithPermission() request completed.')
            );

        this.network.getLists("apps")
            .subscribe( 
                res => {
                    this.apps = res["apps"];
                    console.log("Got app list: ", res);
                },
                err => { this.httpErrorMsg = err.message}
            //    , () => console.log('getUsersWithPermission() request completed.')
            );
    }

    ngOnInit() {
        console.log("New Ticket page got ticketid: ", this.ticketid);
        this.titleService.setTitle( "Ticket " + this.ticketid );
        this.loadTicket( this.ticketid );
    }

    createNewTicket() {
        this.ticket = <Ticket>{ 
            id: 'new',
            app: "",
            status: 1,      // Open
            flagged: 0,
            complaint: 0,
            owner: "",
            isprivate: false,
            workaround: "",
            reported: this.authTokens.getUser().fullname,
            vendor: "",
            source: "",
            source_other: "",
            platform: "",
            description: "",
            summary: "",
            date_modified: "",
            branches:[], 
            files:[], 
            logs:[], 
            comments:[] 
        };

        console.log("Created new ticket: ", this.ticket);

        this.finishNewOrLoadTicket();
    }

    loadTicket( ticketid:string ) {
        console.log("Requesting ticket via HTTP: ", ticketid);

        if (ticketid.toLowerCase() == "new")
            this.createNewTicket();
        else
            this.network.getTicket( ticketid )
                .subscribe(
                    res => {
                        console.log("Got response from server: ", res);

                            // Server will return null when the record could not be found.
                        if (res == null)  {
                            this.httpErrorMsg = "Ticket " + this.ticketid + " does not exist.";
                            this.ticket.summary = "Not found"; // Stop the progress spinner from showing
                        }
                        else
                        {
                            console.log("Got ticket: ", res);

                            this.isSaving = false;

                            this.ticket = res;

                            QMSUtils.fixTicketSchema( this.ticket );

                                // Code from old QMS:
                            if (typeof this.ticket.files == 'undefined')
                                this.ticket.files = [];
                            if (typeof this.ticket.branches == 'undefined')
                                this.ticket.branches = [];
                            if (typeof this.ticket.comments == 'undefined')
                                this.ticket.comments = [];              

                                // New upgrade code: use true/false instead of 0/1 for checkboxes so we can use ngModel
                            this.ticket.isprivate = (this.ticket.isprivate) ? true : false;
                                
                            /* Old QMS code for creating a new ticket:
                                $location.path('new');
                                this.ticket.id = 'new';
                                this.ticket.status=1;
                                this.ticket.flagged=0;
                                this.ticket.complaint=0;
                            */

                            
                        //    this.ticketStatus = this.ticket.status;
                        //    this.ticketOwner = this.ticket.owner;

                            this.finishNewOrLoadTicket();

                            console.log("Loaded ticket: ", this.ticket);
                        }
                            // TODO: Disable everything if job is closed
                    //   if (this.job.status >= 4) {
                    //        $scope.setDisabled(true);
                },
                err => { this.httpErrorMsg = err.message}
            //    , () => console.log('getJob() request completed.')
            );
    }

    finishNewOrLoadTicket() {
        this.prevTicket = JSON.parse( JSON.stringify( this.ticket ) ); // Deep copy that DOES work!!
        this.changedFields = [];
        this.changedSentence = "";
        this.newComment = "";
        this.updateIsDisabled();
    }

    updateIsDisabled()
    {
        this.isDisabled = (this.ticket.status != QMSConst.TICKET_STATUS_OPEN && this.ticket.status != QMSConst.TICKET_STATUS_WISHLIST && 
                    this.ticket.status != QMSConst.TICKET_STATUS_FIX_NEXT_MINOR && this.ticket.status != QMSConst.TICKET_STATUS_DEFER_NEXT_MAJOR);
    }

    saveTicket() {
        this.updateChangedFields();

        this.isSaving = true;
    
        if (this.ticket.id === 'new') {
            
            this.network.getNewTicketID()
                .subscribe(
                    res => {
                        if (res.worked)
                        {
                            console.log("Get new ticketID result: ", res);
                            this.ticket.id = res.id;
                            this.ticketid = res.id; // Also change the ID we intially obtained from the URL's route
                            this.finishSaveTicket(true, "Created ticket");
                        }
                        else
                            this.httpErrorMsg = "Error: server failed to create a new ID for this ticket. " + res.error;
                    },
                    err => { this.saveResult = err.message; this.isSaving = false;}
                //    , () => console.log('saveJob() request completed.')
                );
        } 
        else
            this.finishSaveTicket(false, "Changed " + this.getChangedSentence());
    }

    finishSaveTicket( wasNewTicket:boolean, actionStr:string ) {
        this.network.saveTicket( this.ticket, actionStr, "")
            .subscribe(
                res => {
                    if (res.worked)
                    {
                        if (wasNewTicket)
                            this.router.navigateByUrl("/#/ticket/" + this.ticketid );
                        else
                            this.loadTicket( this.ticketid );
                        
                        this.saveResult = "Save successful.";
                    }
                    else
                    {
                        this.saveResult = "Save failed.";
                        this.isSaving = false;
                        console.log("Save ticket failed. Server returned: ", res);
                    }
                },
                err => { this.saveResult = err.message; this.isSaving = false;}
            //    , () => console.log('saveJob() request completed.')
            );
    }

    createJobFromTicket()
    {
        this.isSaving = true;
        this.network.createJobFromTicket( this.ticket )
            .subscribe(
                res => {
                    if (res.worked)
                    {
                        console.log("Create job from ticket server response: ", res);
                        this.saveResult = "P4 Job created. It will appear in QMS once a changelist is checked in for the job. " + (<any>res).newjobid;
                        this.loadTicket( this.ticketid );
                    }
                    else
                        this.saveResult = "Failed to create job from ticket. Server response: " + res.error;
                },
                err => { this.saveResult = err.message; }
            //    , () => console.log('saveJob() request completed.')
            );
        
    }
    
    addComment() {
        let d = new Date();
        let today = d.getFullYear() + "-" + Utils.pad(d.getMonth()+1, 2) + "-" + Utils.pad(d.getDate(), 2) + " " + Utils.pad(d.getHours(), 2) + ":" + Utils.pad(d.getMinutes(), 2);
        let newComment:TicketComment = {
            name:  this.authTokens.getUser().fullname,
            comment: this.newComment, // TextArea
            date: today
        };
        
        this.ticket.comments.push(newComment);
        this.newComment = "";

        this.saveTicket();
    }
    
    deleteComment(index:number) {
        let obj = {
            title: "Delete and Save", 
            body:"The comment will be deleted AND all changes to this ticket will immediately be saved. This cannot be undone. Proceed?"
        };
        const dialogRef = this.dialog.open( ConfirmDialog, {width: '550px', data: obj} );

        dialogRef.afterClosed().subscribe( (result:boolean) => {
            if (result == true) {
                this.ticket.comments.splice(index, 1);
                this.saveTicket();
            }
        });
    }

    appChanged( event: MatSelectChange )
    {
        this.ticket.app = event.value.module;
        if (this.ticket.owner == "" || this.ticket.owner==undefined)
            this.ticket.owner = event.value.dev1;

        this.updateChangedFields();
    }

    statusOwnerChanged( event: MatSelectChange )
    {
        this.statusMsg = "";
        this.updateChangedFields();
    }

    selectFieldChanged( event: MatSelectChange )
    {
        this.updateChangedFields();
    }

    textFieldChanged( event: Event )
    {
        this.updateChangedFields();
    }

    // Returns an array of job property names that have changed, or an empty array if no changes.
    updateChangedFields()
    {
        this.saveResult = "";

        this.changedFields = [];
        let anyChanged = Utils.hasObjectChanged( this.ticket, this.prevTicket, this.changedFields );

        this.changedSentence = anyChanged ? "Unsaved changes: " + this.getChangedSentence() : "";
    }

        // What has the user changed? Stored in 'action' field of the log on save changes to job
    getChangedSentence():string
    {
        let result = "";
        
        for (let n=0; n < this.changedFields.length; n++)
        {
            if ( this.changedFields[n] == "status" )
                result += "Status to '" + this.QMSConst.TicketStatusIntToString(this.ticket.status) + "'";
             else
                result += this.changedFields[n];

            if (n < this.changedFields.length-1)
                result += ", ";
        }

        return result;
    }
}


