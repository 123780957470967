import {Component, Inject} from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';

/* Usage:

    import { SelectDialog } from 'src/app/dialogs/select/select'

    const dialogRef = this.dialog.open(SelectDialog, {data: {title: "Save changes", body:"Please enter comments and then save.", selectOptions: [{id:number, type:string}], 
            comments: "", requireComments:true}}
        );

    dialogRef.afterClosed().subscribe( (result:{clickedOk:boolean, selectChoice}) => {
        console.log(`Dialog result: ${result}`);
    });
*/

    // AWESOME: Angular actually reports an error on 'build' if the HTML* references a property not in this interface!! Sweet!
export interface SelectDialogData {
    title: string;
    body: string;
    selectLabel: string;
    selectOptions:  Array<{id:number, type:string}>
    okButtonName: string;
}

@Component({
    selector: 'select-dialog',
    templateUrl: 'select.html',
})

export class SelectDialog {
    selectChoice:number = -1;
    readonly minusOne:number = -1;
    constructor(@Inject(MAT_DIALOG_DATA) public data: SelectDialogData) {

    }
}

