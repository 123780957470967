// Various shared helper classes for QMS.

import { Utils } from './utils';

export class QMSConst
{
        // Job stuff:
    static readonly JOB_BRANCHES: string[] = ["Suite2008", "Suite2009", "Suite2010", "Suite2013", "Suite2015", "Suite2017", "Suite2021", "main", "my.csaim"];

        // Deprecated. Used to read legacy jobs.
    static readonly OldJobReviewTypes:Array<{id:number, type:string}> = [
        {id: 1, type: "Author"}, 
        {id: 2, type: "Engineering"}, 
        {id: 3, type: "Management"}, 
        {id: 4, type: "QA"}, 
        {id: 5, type: "Regulatory"}
    ]; 

        // New Job review statuses
    static readonly JOB_STATUS_PLANNED = 10;
    static readonly JOB_STATUS_IN_DEVELOPMENT = 12;
    static readonly JOB_STATUS_CODE_REVIEW = 14;
    static readonly JOB_STATUS_AIMDOC = 15;
    static readonly JOB_STATUS_TESTING = 16;
    static readonly JOB_STATUS_COMPLETE = 18;
    static readonly JOB_STATUS_CLOSED = 20;

    static readonly DEFAULT_JOB_COMPELTE_OWNER = "serge";
    static readonly DEFAULT_JOB_AIMDOC_OWNER = "gregk";

    static readonly JobStatusNumToString:Record<number,string> = {
        10: "Planned",
        12: "In Development",
        14: "Code Review",  // [optional, if populated]
        15: "Documentation",
        16: "Testing",
        18: "Complete",
        20: "Closed"
    };

    static readonly JobStatusNumToReviewString:Record<number,string> = {
        10: "Planning",
        12: "Development",
        14: "Code Review",  // [optional, if populated]
        15: "Documentation",
        16: "Testing",
        18: "Complete [Final Review]",
        20: "Closed"
    };

        // Ticket stuff:
        // Due to legacy QMS database records, TICKET_BRANCHES is different from JOB_BRANCHES above.
    static readonly TICKET_BRANCHES: string[] = ["2022", "2017", "2015", "2013", "2010", "2009", "2008", "mainline", "my.csaim"];
    static readonly TICKET_STATUSES = [ 
        {id:1, type: "Open"},
        {id:2, type: "Closed - Fixed"},
        {id:3, type: "Closed - Not reproducible"},                      
        {id:6, type: "Closed - Duplicate"},
        {id:5, type: "Closed - Unknown Reason"},
        {id:4, type: "Wishlist"},
        {id:8, type: "Fix in next minor release"},
        {id:7, type: "Defer to next Major release"},
        {id:100, type: "Permanent known issue"},
        {id:101, type: "By Design"},
        {id:200, type: "Phone-Fix"}     // Might not be used?                    
    ];

    static readonly TICKET_STATUS_OPEN = 1;
    static readonly TICKET_STATUS_WISHLIST = 4;
    static readonly TICKET_STATUS_FIX_NEXT_MINOR = 8;
    static readonly TICKET_STATUS_DEFER_NEXT_MAJOR = 7;

    static TicketStatusIntToString( statusInt:number ):string
    {
        for (let n=0; n < QMSConst.TICKET_STATUSES.length; n++)
            if (QMSConst.TICKET_STATUSES[n].id == statusInt)
                return QMSConst.TICKET_STATUSES[n].type;
        return "Unknown ticket status id: " + statusInt;
    }

        // TODO: Remove this and instead pull from the 'Releases' list
    static readonly TICKET_SOURCES = ["Other",
        "2017.25", "2017.24", "2017.23", "2017.22", "2017.21", "2017.20", "2017.19", "2017.18", "2017.17", "2017.16", "2017.15", "2017.14", "2017.13", "2017.12", "2017.11", "2017.10", "2017.9", "2017.8", "2017.7", "2017.6", "2017.5", "2017.4", "2017.3", "2017.2", "2017.1", "2017.0", "2017.XX",
        "2015.15", "2015.14", "2015.13", "2015.12", "2015.11", "2015.10", "2015.9", "2015.8", "2015.7", "2015.6", "2015.5", "2015.4", "2015.3", "2015.2", "2015.1", "2015.0", "2015.XX",
        "2013.4", "2013.3", "2013.2", "2013.1","2013.0","2013.XX",
        "2012.7","2012.6","2012.5","2012.4","2012.3","2012.2","2012.1","2012.0","2012.XX"
    ];
    
    static readonly TICKET_VENDORS = ["All","CSMC Direct", "CSMC (internal)","Siemens","GE","Philips","Spectrum","Digirad","Mirada","Thinksys"];
    static readonly TICKET_PLATFORMS = ["All", "Windows", "MAC", "Linux"];
    static readonly TICKET_SEVERITY = ["Low","Minor","Major","Critical","Blocker"];
    static readonly TICKET_PROBABILITY = ["<1%", "1-10%", "11-25%", "26-50%", "51-99%", "100%"]; // NOTE: This was set for only 3 records in legacy QMS tickets. :-D

    static TicketStatusCodeToString( statusCode:number ):string {
        for (let n=0; n < QMSConst.TICKET_STATUSES.length; n++)
            if ( QMSConst.TICKET_STATUSES[n].id == statusCode)
                return QMSConst.TICKET_STATUSES[n].type;
        return "Unknown ticket status: " + statusCode;
    }

        // Test Worksheet stuff
    static readonly TW_SUITES = [
        {id:"2017",type:"Suite2017"}, 
        {id:"2015",type:"Suite2015"}, 
        {id:"2013",type:"Suite2013"}, 
        {id:"2012",type:"Suite2012"}, 
        {id:"9999",type:"Mainline"}, 
        {id:"2009",type:"Suite2009"}, 
        {id:"2008",type:"Suite2008"}, 
        {id:"1.0",type:"Suite1.0"}, 
        {id:"cloud-p",type:"Cloud Production"}, 
        {id:"cloud-d",type:"Cloud Development"}, 
        {id:"ipad",type:"iPad "}
    ];
    static readonly TW_VENDORS = ["csmcdirect", "siemens", "philips", "ge", "digirad"];
    static readonly TW_OS = ["Win 7", "Win 8", "OS X", "Win 10", "Win 11", "Server 2012", "Server 2016", "Server 2019", "Server 2022", "Other"];
    static readonly TW_HARDWARE = ["M-T1500", "M-GX620", "M-VM1", "M-VM2", "M-VM3", "Other"];

        // The following are used to convert legacy Test Worksheet 'tester' fields to modern QMS 'testerUsername', done on load
    static readonly TW_TESTERID_TO_USERNAME = ["geoff", "serge", "tejas", "parker", "pbk", "vern", "jwei", "ysingh", "alya"]; // DO NOT MODIFY. Converts legacy QMS 'tester' Test Worksheet array index into corresponding modern CRM username.
    static readonly TW_TESTER_OBJECTID_TO_USERNAME:Record<string,string> = {
        "51bfb6dae1f702f2700d294e": "geoff",
        "51bfb7b6ac76c0e00f000000": "serge",
        "51e9a6deac76c0201e000000": "tejas",
        "51e9ad58ac76c0201e000001": "parker",
        "51f857feac76c0a818000000": "pbk",
        "51f8581eac76c0a818000001": "vern",
        "51f85880ac76c0a818000002": "jwei",
        "5229652cac76c0f80d000000": "manisha",
        "53a9e8d4ac76c0b40e000000": "rob",
        "53bdcc7bac76c01817000000": "piotr", 
        "57a8b7fb0f0d08a811000030": "ysingh",
        "5ece79260f0d088804000029": "alya"
    };

    static readonly RELEASES = [
            // Use 999999 for 'end' when it's currently the most recent dot release
            // To get the end changelist number, see:    \\essnfs03\AIM_Files\builds\2017
            // The start changelist number for the next dot release is the ending number of the previous + 1
        {name:"2017.24.1", branch:"Suite2017.24", start:"37912", end: "999999"},
        {name:"2017.23.1", branch:"Suite2017.23", start:"37911", end: "999999"},
        {name:"2017.25", branch:"Suite2017", start:"37137", end: "999999"},
        {name:"2017.24", branch:"Suite2017", start:"36457", end: "37136"},
        {name:"2017.23", branch:"Suite2017", start:"35721", end: "36456"},
        {name:"2017.22", branch:"Suite2017", start:"35430", end: "35720"},
        {name:"2017.21", branch:"Suite2017", start:"34955", end: "35429"},
        {name:"2017.20", branch:"Suite2017", start:"34819", end: "34954"},
        {name:"2017.19", branch:"Suite2017", start:"34636", end: "34818"},
        {name:"2017.18", branch:"Suite2017", start:"33873", end: "34635"},
        {name:"2017.17.1", branch:"Suite2017", start:"33823", end:"33872"},
        {name:"2017.17", branch:"Suite2017", start:"33518", end: "33822"},
        {name:"2017.16", branch:"Suite2017", start:"33171", end: "33517"},
        {name:"2017.15", branch:"Suite2017", start:"32249", end: "33170"},  
        {name:"2017.14", branch:"Suite2017", start:"32031", end: "32248"}, 
        // {name:"2017.13.1", branch:"Suite2017.13", start:"34142", end: "999999"},
        {name:"2017.13", branch:"Suite2017", start:"31938", end: "32030"},
        {name:"2017.12", branch:"Suite2017", start:"31593", end: "31937"},
        {name:"2017.11", branch:"Suite2017", start:"31474", end: "31592"},
        {name:"2017.10", branch:"Suite2017", start:"31358", end: "31473"},
        {name:"2017.09", branch:"Suite2017", start:"31155", end: "31357"},
        {name:"2017.08", branch:"Suite2017", start:"30778", end: "31154"},
        {name:"2017.07.04", branch:"Suite2017", start:"31744", end: "31745"},
        {name:"2017.07", branch:"Suite2017", start:"30607", end: "30777"},
        {name:"2017.06", branch:"Suite2017", start:"30226", end: "30606"},   
        {name:"2017.05", branch:"Suite2017", start:"29697", end: "30225"}, 
        {name:"2017.04", branch:"Suite2017", start:"29251", end: "29696"},  
        {name:"2017.03", branch:"Suite2017", start:"28867", end: "29250"},  
        {name:"2017.02", branch:"Suite2017", start:"28688", end: "28866"},
        {name:"2017.01", branch:"Suite2017", start:"27681", end: "28687"},
        {name:"2017.0", branch:"Suite2017", start:"27566", end: "27680"},

        {name:"2015.16", branch:"Suite2015", start:"31546", end: "99999"},   
        {name:"2015.15", branch:"Suite2015", start:"30690", end: "31138"},  
        {name:"2015.14", branch:"Suite2015", start:"28253", end: "30689"}, 
        {name:"2015.13", branch:"Suite2015", start:"27475", end: "28252"},
        {name:"2015.12", branch:"Suite2015", start:"26552", end: "27474"},  
        {name:"2015.11", branch:"Suite2015", start:"25896", end: "26551"}, 
        {name:"2015.10", branch:"Suite2015", start:"24290", end: "25895"},  
        {name:"2015.09", branch:"Suite2015", start:"25255", end: "25648"}, 
        {name:"2015.08", branch:"Suite2015", start:"25132", end: "25254"},
        {name:"2015.07", branch:"Suite2015", start:"24335", end: "25131"},
        {name:"2015.06", branch:"Suite2015", start:"24018", end: "24334"},
        {name:"2015.05", branch:"Suite2015", start:"23506", end: "24017"},
        {name:"2015.04", branch:"Suite2015", start:"23332", end: "23505"},
        {name:"2015.03", branch:"Suite2015", start:"22950", end: "23331"},
        {name:"2015.02", branch:"Suite2015", start:"22423", end: "22949"},
        {name:"2015.01", branch:"Suite2015", start:"22012", end: "22422"},
        {name:"2015.0", branch:"Suite2015", start:"0", end: "22011"},  

            // Note: these were commented out in old QMS, and there was a 2013.9 entry with end: "9999" that I removed.
        {name:"2013.8", branch:"Suite2013", start:"22496", end: "22908"},  
        {name:"2013.7", branch:"Suite2013", start:"21948", end: "22495"},
        {name:"2013.6", branch:"Suite2013", start:"21693", end: "21947"},
        {name:"2013.5", branch:"Suite2013", start:"21271", end: "21692"},
        {name:"2013.4", branch:"Suite2013", start:"20488", end: "21270"},
        {name:"2013.3", branch:"Suite2013", start:"20141", end: "20487"},
        {name:"2013.2", branch:"Suite2013", start:"19763", end: "20140"},
        {name:"2013.1", branch:"Suite2013", start:"19363", end: "19762"},
        {name:"2013.0", branch:"Suite2013", start:"0", end: "19362"}
    ];     
}


export class JobStatus
{
       // Converts a job status int to string.
        // IMPORTANT: Should match equivalent PHP code in php-api\design.php
 //   static readonly JobStatuses: string[] = ["Open", "Review", "To Test", "Final Review", "Closed"];
    static readonly JobStatusColors: Record<number, string> = {
        10:"green", 12: "purple", 14: "orange", 16: "brown", 18:"SlateBlue", 20: "blue"};

   /* static JobStatusIntToString( statusInt: number ):string
    {
        if (statusInt >= 0 && statusInt < JobStatus.JobStatuses.length)
            return JobStatus.JobStatuses[ statusInt ];
        else
            return "Invalid job status: " + statusInt;
    }

    static JobStatusStringToInt( statusString:string ):number
    {
        for (let n=0; n < JobStatus.JobStatuses.length; n++)
            if (JobStatus.JobStatuses[n] == statusString)
                return n;
        return -1;
    }*/

    static GetJobStatusColor( statusInt: number ):string
    {
        if ( JobStatus.JobStatusColors[ statusInt ] != undefined)
            return JobStatus.JobStatusColors[ statusInt ];
        else
            return "black";
    }
}

export class QMSUtils
{
        // Legacy QMS job records have various discrepancies, which cause problems if not fixed. According to Geoff, this is due to the fact
        // that most job documents are created by the Python P4 import script, but a few might have been created by users directly in QMS.
        //   1) The vast majority of job documents have tests that have 'guid' fields and not tmpid.
        //      31 records, however, use tmpid instead of guid. We fix those records here.
        //   2) The job 'status' field is an integer for the majority of records, a string "0" for some (causing the Status menu to show as empty),
        //      and a float (4.0 for closed) for others. The latter one does not cause problems for the client, but we parse all to an integer anyway.
    static fixJobSchema( job:Job )
    {
        if (!job)
            return;

            // The following is code from old QMS
        if (typeof job.branches == 'undefined')
            job.branches = [];            
        if (typeof job.risklevel == 'undefined')
            job.risklevel = "tbd";
        for (let i=0; i < job.tests.length; i++)
            job.tests[i].testid = Utils.pad(job.tests[i].testid, 2, '0');

            // 1. Fix tests
        for (let n=0; n < job.tests.length; n++)
            if ( typeof job.tests[n].guid == 'undefined' && job.tests[n].tmpid )
                job.tests[n].guid = <string>job.tests[n].tmpid;
        
            // 2. Fix the old status
        if (job.status)
            job.status = parseInt(<any>job.status, 10);

            // 3. Fix job Review 'type' field which should be a number, but was saved as a string (i.e. "2")
        if (job.reviews)
            for (let n=0; n < job.reviews.length; n++)
                job.reviews[n].type = parseInt(<any>job.reviews[n].type, 10);

            // 4. Make sure 'notests' exists (some records instead have no_tests, others have both)
        if (typeof job.notests == 'undefined')
            job.notests = (job.no_tests) ? true : false; 


            // Convert legacy job status to new status if new status does not exist
        if ( job.statusNew == undefined )
        {
            if (job.status == 0) // Open
                job.statusNew = QMSConst.JOB_STATUS_IN_DEVELOPMENT;
            else if (job.status == 1) // Review
                job.statusNew = QMSConst.JOB_STATUS_CODE_REVIEW;
            else if (job.status == 2) // To Test
                job.statusNew = QMSConst.JOB_STATUS_TESTING;
            else if (job.status == 3) // Final Review
                job.statusNew = QMSConst.JOB_STATUS_COMPLETE;
            else if (job.status == 4) // Closed
                job.statusNew = QMSConst.JOB_STATUS_CLOSED; 

                // Remove former job status so MongoDB queries use the new value only
            delete job.status;
        }
    }

    static fixTicketSchema( ticket:Ticket )
    {
        if (!ticket)
            return;

            // Code from old QMS:
        if (typeof ticket.files == 'undefined')
            ticket.files = [];
        if (typeof ticket.branches == 'undefined')
            ticket.branches = [];
        if (typeof ticket.comments == 'undefined')
            ticket.comments = [];   
            
            // New upgrade code: use true/false instead of 0/1 for checkboxes so we can use ngModel
        ticket.isprivate = (ticket.isprivate) ? true : false;
    }

    static fixTestWorksheetSchema( tw:TestWorksheet )
    {
        if (!tw)
            return;

            // Legacy Test Worksheets from old QMS had two DIFFERENT types of data stored in its schema for the 'tester' field!!
            // The oldest records had an array index -- an index into an array returned from the server when all users in group 'TESTER' were returned. 
            // Even old QMS does not show these worksheets properly: it shows a blank menu for 'tester' in this case.
            // The second kind, used by most records, is a MongoDB ObjectID referring to the user record, i.e. "5228eb8cac76c04809000000"
            // I have copied all these values from old QMS's database and put them in an array for easy conversion to a username that exists in our new CRM.
            // Do do this conversion only if we haven't done so already! Otherwise, we'd lose any changes the user makes to this field for legacy records.
        if ( typeof tw.testerUsername === 'undefined' && typeof tw.tester !== 'undefined' )
        {
            if (typeof tw.tester == "string" )
            {
                let myStr:string = <string>tw.tester;
                tw.testerUsername = QMSConst.TW_TESTER_OBJECTID_TO_USERNAME[ myStr ];
                if (typeof tw.testerUsername === 'undefined' )
                tw.testerUsername = "";        // Unknown tester
            }
            else
            {
                if (tw.tester >= 0 && tw.tester < QMSConst.TW_TESTERID_TO_USERNAME.length)
                    tw.testerUsername = QMSConst.TW_TESTERID_TO_USERNAME[ <number>tw.tester ];
                else
                    tw.testerUsername = "";    // Unknown tester
            }
        }

            // Do any required sorting
        Utils.sortBy( tw.tests, 'testid');
    }
}
