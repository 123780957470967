<div class="col-md-12">
    <div class="row">
        <div class="col-sm-12">
            <div class="panel panel-primary">
                <div class="panel-heading">
                    <h3 class="panel-title">Developer</h3>
                </div>
                <div class="panel-body">

                    <div class="row">
                        <div class="col-md-8">
                            <mat-form-field appearance="outline">
                                <mat-label>Email address</mat-label>
                                <input matInput [(ngModel)]='emailAddress' type="email">
                            </mat-form-field>
                        </div>
                    </div>

                    <button mat-raised-button color="primary" (click)="sendEmail()">Send Test Email</button>

                    {{emailAddress}}
                </div>
            </div>
        </div>
    </div>
</div>