import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AuthTokens } from '../../app.services';
import { QMSNetwork } from '../../qmsnetwork.service';


@Component({
    selector: 'developer-page',
    templateUrl: './developer.html',
})
export class DeveloperPage implements OnInit {

    emailAddress: string = "";

    constructor(private network: QMSNetwork, private authTokens: AuthTokens, private titleService: Title ) {
        console.log("//-----------------------------------------------");
        console.log("// Developer page constructor entered");
        console.log("//-----------------------------------------------");
        
        this.titleService.setTitle( "Developer" );
    }

    ngOnInit() {
  
    }

    sendEmail() {
            // this.emailAddress
        this.network.sendEmail( this.emailAddress, "Test email from my.csaim.com", "This is a test email from my.csaim.com sent using the Developer page.", 
                ["vern.jensen@cshs.org", "vernsads@gmail.com"])
            .subscribe(
                res => {
                    console.log("Send email server response: ", res);
                },
                err => { console.log("sendEmail HTTP error:" + err.message);}
         //     , () => console.log('getTickets() request completed.')
            );
    }
}


