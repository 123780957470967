
<!-- If this is the special 'Jobs ready for testing' panel, show it only if the user is a QA user. -->
<div class="col-md-12" *ngIf="paneltype != 'testing' || isQAUser">
    <div class="row">
        <div class="col-sm-12">
            <div class="panel panel-primary">
                <div class="panel-heading">
                    <h3 class="panel-title">{{jobPanelTitle}}</h3>
                    
                </div>
                <div class="panel-body">
                    
                    <div class="mat-elevation-z8">

                        <div class="row" style="margin-left:20px">
        
                                <!-- Show Columns multiselect -->
                            <div class="col-md-1" *ngIf="!ishomepage">
                                <mat-form-field appearance="legacy">
                                    <mat-select [(value)]="displayedColumns" multiple>
                                        <mat-select-trigger>
                                            Show
                                        </mat-select-trigger>
                                        <mat-option *ngFor="let item of allColumns" [value]="item">{{item}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        
                            <div class="col-md-2" *ngIf="!ishomepage">
                            </div>

                                <!-- Search -->
                            <div class="col-md-4">
                                <mat-form-field>
                                    <mat-label>Search</mat-label>
                                    <input matInput (keyup)="applyFilter($event)" placeholder="" #input autocomplete="off">
                                </mat-form-field>
                            </div>

                                <!-- Paginator -->
                            <mat-paginator style="float: right; margin-right:20px;" [pageSizeOptions]="[5, 10, 20, 30, 40, 50, 100]"
                                           (page)="handlePageEvent($event)">
                            </mat-paginator>
                        </div>
                        
                        <table mat-table [dataSource]="dataSource" matSort>

                            <!-- Checkbox Column -->
                        <!--
                            <ng-container matColumnDef="select">
                                <th mat-header-cell *matHeaderCellDef style="width: 4%;">
                                    <mat-checkbox (change)="$event ? masterToggle() : null; checkboxClicked();"
                                                [checked]="selection.hasValue() && isAllSelected()"
                                                [indeterminate]="selection.hasValue() && !isAllSelected()">
                                    </mat-checkbox>
                                </th>
                                <td mat-cell *matCellDef="let row">
                                    <mat-checkbox (click)="$event.stopPropagation();"
                                                (change)="$event ? selection.toggle(row) : null; checkboxClicked();"
                                                [checked]="selection.isSelected(row)">
                                    </mat-checkbox>
                                </td>
                            </ng-container>
                        -->
                            
                            <!-- Job ID Column -->
                            <ng-container matColumnDef="jobid">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10%;"> ID </th>
                                <!-- For now this is an old angular.js style link. Switch from href to routerLink when upgrading to a Angular link! And add: routerLinkActive="active" -->
                                <td mat-cell *matCellDef="let row"> <a href="/#/job/{{row.jobid}}">{{row.jobid}}</a> </td>
                            </ng-container>
                            
                            <!-- Status Column -->
                            <ng-container matColumnDef="statusNew">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 8%;"> Status </th>
                                <td mat-cell *matCellDef="let row" [style.color]="JobStatus.GetJobStatusColor(row.statusNew)"> {{QMSConst.JobStatusNumToString[row.statusNew]}} </td>
                            </ng-container>
                
                            <!-- Owner Column -->
                            <ng-container matColumnDef="owner">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10%;"> Owner </th>
                                <td mat-cell *matCellDef="let row"> {{row.owner}} </td>
                            </ng-container>

                            <!-- Type Column -->
                            <ng-container matColumnDef="job_type">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 5%;"> Type </th>
                                <td mat-cell *matCellDef="let row"> {{row.job_type}} </td>
                            </ng-container>

                            <!-- Branches Column -->
                            <ng-container matColumnDef="branches">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10%;"> Branches </th>
                                <td mat-cell *matCellDef="let row"> {{row.branches}} </td>
                            </ng-container>
                            
                            <!-- Risk Column -->
                            <ng-container matColumnDef="risklevel">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 5%;"> Risk </th>
                                <td mat-cell *matCellDef="let row"> {{row.risklevel}} </td>
                            </ng-container>

                            <!-- # Tests Column -->
                            <ng-container matColumnDef="tests">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 5%;"> # Tests </th>
                                <td mat-cell *matCellDef="let row"> {{row.tests.length}} </td>
                            </ng-container>
                
                            <!-- Summary Column -->
                            <ng-container matColumnDef="summary">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 36%;"> Summary </th>
                                <td mat-cell *matCellDef="let row"> {{row.summary}} </td>
                            </ng-container>
                
                            <!-- Owner Column -->
                            <ng-container matColumnDef="ticketid">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10%;">Tickets</th>
                                <td mat-cell *matCellDef="let row"> {{row.ticketid}} </td>
                            </ng-container>
                    
                        <!-- Color Column
                        <ng-container matColumnDef="color">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Color </th>
                            <td mat-cell *matCellDef="let row" [style.color]="row.color"> {{row.color}} </td>
                        </ng-container>
                        -->
                    
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    
                        <!-- Row shown when there is no matching data. -->
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                        </tr>
                        </table>
                        
                        <span style="display: inline-block; height: 80px;">
                            <mat-spinner *ngIf="isLoading" [diameter]="progressSpinnerSize" style="margin: 15px;"></mat-spinner>
                        </span>
                    </div>

                    <p style="color:red"><b>{{httpErrorMsg}}</b></p>
                </div>
            </div>
        </div>
    </div>
</div>