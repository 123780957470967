import {Component, Inject} from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';

/* Usage:

    import { CommentDialog } from 'src/app/dialogs/comment/comment'

    const dialogRef = this.dialog.open(CommentDialog, {data: {title: "Save changes", body:"Please enter comments and then save.", 
            comments: "", requireComments:true}}
        );

    dialogRef.afterClosed().subscribe( (result:boolean) => {
        console.log(`Dialog result: ${result}`);
    });
*/

    // AWESOME: Angular actually reports an error on 'build' if the HTML* references a property not in this interface!! Sweet!
export interface CommentDialogData {
    title: string;
    body: string;
    comments: string;
    action: string;
    requireComments: boolean;
    okButtonName: string;
}

@Component({
    selector: 'comment-dialog',
    templateUrl: 'comment.html',
})

export class CommentDialog {
    constructor(@Inject(MAT_DIALOG_DATA) public data: CommentDialogData) {

    }
}

