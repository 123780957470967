<h2 mat-dialog-title>{{data.title}}</h2>

<mat-dialog-content class="mat-typography">
    <p>{{data.body}}</p>

    <mat-form-field appearance="outline">
        <mat-label>{{data.selectLabel}}</mat-label>
        <mat-select [(value)]="selectChoice">
            <mat-option [value]="minusOne">None</mat-option>
            <mat-option *ngFor="let item of data.selectOptions;" [value]="item.id">{{item.type}}</mat-option>
        </mat-select>
    </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="{clickedOk: false}">Cancel</button>
    <button mat-button [mat-dialog-close]="{clickedOk: true, selectChoice: selectChoice}" cdkFocusInitial color="primary" [disabled]="selectChoice == -1">{{data.okButtonName}}</button>
</mat-dialog-actions>