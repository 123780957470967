<h2 mat-dialog-title>{{data.title}}</h2>

<mat-dialog-content class="mat-typography">
    <p>{{data.body}}</p>
    <p><b>Action: </b>{{data.action}}</p>

    <mat-form-field appearance="outline" style="width:100%">
        <mat-label>Comments</mat-label>
        <textarea [(ngModel)]='data.comments' matInput style="min-height:100px;" cdkTextareaAutosize></textarea>
    </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="{clickedOk: false}">Cancel</button>
    <button mat-button [mat-dialog-close]="{clickedOk: true}" cdkFocusInitial color="primary" [disabled]="data.requireComments && data.comments.length == 0">{{data.okButtonName}}</button>
</mat-dialog-actions>