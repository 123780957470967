import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
// import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';

import { AuthTokens, LocalUserPreferences } from '../../app.services';
import { QMSNetwork } from '../../qmsnetwork.service';
import { JobStatus, QMSConst, QMSUtils } from '../../qms.classes';



@Component({
  selector: 'jobs-page',
  templateUrl: './jobs.html',
  styleUrls: ['./jobs.css']
})
export class JobsPage implements AfterViewInit, OnInit {
        // @ts-ignore
    @ViewChild(MatPaginator) paginator: MatPaginator;
        // @ts-ignore
    @ViewChild(MatSort) sort: MatSort;

        // @ts-ignore
    @Input() ishomepage: boolean;       // true, false, or undefined. If true, show only open tickets for the current user
        // @ts-ignore
    @Input() paneltype: string; // Optional. If specified, shows 'testing pool' jobs only, or still-open jobs originally authored by a specific user.

        // Don't forget! When adding new columns to the html, you must add them here too!
        // BUG FIX: For sorting to work, the names below must EXACTLY match the name of the object property you are displaying 
    allColumns: string[] = ['jobid', 'statusNew', 'owner', 'job_type', 'branches', 'risklevel', 'summary', 'ticketid', 'tests']; // TODO: Add more here
    displayedColumns: string[] = ['jobid', 'statusNew', 'owner', 'summary', 'branches', 'ticketid'];
    dataSource: MatTableDataSource<Job>;


    //selection = new SelectionModel<Job>(true, []);
    //numSelected: number = 0;

    readonly JobStatus = JobStatus; // Expose this class to our HTML template so it can call its methods
    readonly QMSConst = QMSConst;
    readonly progressSpinnerSize = 30;

    queryOwner: string = "any";                // username or "any" for all users
    queryStatuses: number[] = [];    // Empty array will show jobs for all statuses. Otherwise pass an array of values such as: [QMSConst.JOB_STATUS_PLANNED]
                                    // If you specify values, ONLY jobs created within the 'new' QMS will show up, since old jobs do not have the new status codes!!

    jobPanelTitle = "Jobs";
    isQAUser: boolean = false;
    isLoading: boolean = true;
    httpErrorMsg:string = "";

    sortIsAsc: boolean = true;
    pageSize: number = 10;
    

    constructor(private network: QMSNetwork, private authTokens: AuthTokens, private titleService: Title, private userPref: LocalUserPreferences) {
        console.log("//-----------------------------------------------");
        console.log("// Jobs page constructor entered");
        console.log("//-----------------------------------------------");

        this.dataSource = <any>new MatTableDataSource([]);
        this.titleService.setTitle( "Jobs" );
    }

    ngOnInit() {
        let user = this.authTokens.getUser();
        console.log("Jobs page got user:", user);
        if (!user.valid)
        {
            this.httpErrorMsg = "You must be logged in to access this content.";
            return;
        }

        this.isQAUser = user.groups.includes("QA");
        console.log("Current user: ", this.authTokens.getUser());

            // When this component is used from the user's Home page, show only jobs for the logged-in user
        if (this.ishomepage) {
            this.queryOwner = user.username;
        }

        if (this.paneltype == "testing")
            this.jobPanelTitle = "Jobs needing testing (unclaimed)";
        else if (this.paneltype == "author")
            this.jobPanelTitle = "Jobs you developed that are waiting on others";
        console.log("Job paneltype = ", this.paneltype);

        // page size preference
        let size = this.userPref.getJobsPageSize(this.paneltype);
        if (size && size > 0) this.pageSize = size;

        this.loadJobs();
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        if (this.dataSource.paginator)
            this.dataSource.paginator.pageSize = this.pageSize;
        this.dataSource.sort = this.sort;
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator)
             this.dataSource.paginator.firstPage();
    }

    loadJobs() {
        let queryStatuses = this.queryStatuses;
        let queryOwner = this.queryOwner;
        if (this.paneltype == "testing")
        {
            queryStatuses = [QMSConst.JOB_STATUS_TESTING];
            queryOwner = "none"; // Only jobs with no owner (unclaimed by QA staff yet)

            this.network.getJobs(queryOwner, queryStatuses)
                .subscribe(
                    res => {
                        console.log("getJobs() for testing API result: ", res);

                        if (res)
                        {
                            for (let n=0; n < res.length; n++)
                                QMSUtils.fixJobSchema( res[n] );

                            this.dataSource.data = res;
                        }
                        else
                            this.httpErrorMsg = "No jobs found. Please make sure you are logged in.";

                        this.isLoading = false;
                    },
                    err => { this.httpErrorMsg = err.message}
            //     , () => console.log('getDBInfo() request completed.')
                );
        }
        else if (this.paneltype == "author")
        {
            this.network.getOpenJobs("developerOwner", this.authTokens.getUser().username)
                .subscribe(
                    res => {
                        console.log("getOpenJobs() API result: ", res);

                        for (let n=0; n < res.length; n++)
                            QMSUtils.fixJobSchema( res[n] );

                        this.dataSource.data = res;
                        this.isLoading = false;
                    },
                    err => { this.httpErrorMsg = err.message}
            //     , () => console.log('getDBInfo() request completed.')
                );
        }
        else
        {       // Normal open jobs query, either by owner, or 'any'
            this.network.getOpenJobs("owner", queryOwner)
                .subscribe(
                    res => {
                        console.log("getOpenJobs() API result: ", res);

                        for (let n=0; n < res.length; n++)
                            QMSUtils.fixJobSchema( res[n] );

                        this.dataSource.data = res;
                        this.isLoading = false;
                    },
                    err => { this.httpErrorMsg = err.message}
            //     , () => console.log('getDBInfo() request completed.')
                );
        }
    }
    
    handlePageEvent(e: PageEvent) {
        if (this.pageSize != e.pageSize) {
            this.pageSize = e.pageSize;
            this.userPref.setJobsPageSize(this.paneltype, this.pageSize);
        }
    }

    //--------------------------------------------------------------------------------------------
    // Checkbox selection handling
    //--------------------------------------------------------------------------------------------

/*
    isAllSelected() {
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return numSelected === numRows;
    }

        // Selects all rows if they are not all selected; otherwise clear selection.
    masterToggle() {
      this.isAllSelected() ?
          this.selection.clear() :
          this.dataSource.data.forEach(row => this.selection.select(row));
    }

    checkboxClicked()
    {
        this.numSelected = this.selection.selected.length;
        this.enableChangeStatusButton = true;
        this.printSelected();
    }

    printSelected()
    {
        console.log("Currently selected jobs: ");
        for (const job of this.selection.selected)
            console.log(job);
    }

*/

}


