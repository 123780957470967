// Currently unused.

import { Component } from '@angular/core';

console.log("app.component.ts loaded.");

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.less']
})

export class AppComponent
{
	title = 'qms';
	
	constructor() {
		console.log("*** Angular 2 AppComponent has been entered!");
	}
	
	ngOnInit()
	{
		console.log("AppComponent ngOnInit() entered.");
	}
}
