// CURRENTLY UNUSED.\
// Reason: index.html references angular.js <ng-view> and so our site is an 'angular.js' site at the root.
// Angular pages must be downgraded to show up. We can't route to them here until we're a fully modern Angular site.

import { NgModule } from '@angular/core';
import { Routes, RouterModule, UrlSegment } from '@angular/router';

import { AppComponent } from './app.component';


/*
    // Match any URL that starts with `users` to old angular.js
export function isAngularJSUrl(url: UrlSegment[]) {
	return url.length > 0 && url[0].path.startsWith('users') ? ({consumed: url}) : null;
}*/

export const routes: Routes = [
  // Don't add new Angular page rroutes here! Instead for now we downgrade Angular pages to angular.js in app.module.ts! 
  // Then we link to them just like we would any angular.js page
//  { path: 'jobs', component: JobsPage },
 // { path: 'job', component: JobPage },


  // Catch-all route
  { path: '**', component: AppComponent } // App404Component
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
