<div *ngIf="currentDatabase.length > 0">
    <br />
    <b style="color:purple">{{currentDatabase}}</b>
    <br /><br />
</div>

<div class="col-md-12">
    <div class="row">
        <div class="col-sm-12">
            <div class="panel panel-success">
                <div class="panel-heading">
                    <div class="row">
                        <div class="col-md-5">
                            <h3 class="panel-title">
                                Test Worksheet for {{jobid}}
                                <a class="btn btn-mini vcenter" href="/#/job/{{jobid}}" target="_blank" title="Open Job {{jobid}} in New Window"><i class="fa fa-share" aria-hidden="true"></i></a>
                            </h3>
                        </div>

                        <div class="col-md-4" *ngIf="hasTicket()">
                            <h3>
                                Ticket Fix: {{job.ticketid}}
                                <a class="btn btn-mini vcenter" href="#/ticket/{{ticket.id}}" target="_blank" title="Open Ticket {{ticket.id}} in New Window">
                                    <i class="fa fa-share" aria-hidden="true"></i></a> 
                            </h3>
                        </div>
                    </div>

                    <!-- Job summary -->
                    <div class="row" *ngIf="job && job.jobid">
                        <div class="col-md-12">
                            <table>
                                <tr>
                                    <td width="150px">
                                        <b>Job summary:</b>
                                    </td>
                                    <td>
                                        {{job.summary}}
                                    </td>
                                </tr>
                                <tr>&nbsp;</tr>
                                <tr>
                                    <td width="150px">
                                        <b>Job requirements:</b>
                                    </td>
                                    <td>
                                        {{job.requirements}}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="row" *ngIf="!job || !job.jobid">
                        Job {{jobid}} could not be found.
                    </div>
                    
                </div>
                <div class="panel-body">
                    
                    <div *ngIf="isLoading">
                        <span style="display: inline-block; height: 80px;">
                            <mat-spinner [diameter]="progressSpinnerSize" style="margin: 15px;"></mat-spinner>
                        </span>
                    </div>

                    <div *ngIf="tw._id || twid == 'new'">
                        <mat-card style='background-color:#f8fff8; margin-bottom:30px;'>

                            <div class="row">
                                <div class="col-md-2">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Tester</mat-label>
                                        <mat-select [(value)]="tw.testerUsername" [disabled]="isDisabled">
                                            <mat-option *ngFor="let user of users" value="{{user.username}}">{{user.fullname}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div class="col-md-2">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Suite</mat-label>
                                        <mat-select [(value)]="tw.suite" [disabled]="isDisabled" (selectionChange)="updateReleasesShortcutMenu()">
                                            <mat-option *ngFor="let item of QMSConst.TW_SUITES;" [value]="item.id">{{item.type}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div class="col-md-2">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Build number</mat-label>
                                        <input matInput [(ngModel)]='tw.buildnum' autocomplete="off" [disabled]="isDisabled">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-1" style="margin-left:-30px;">
                                    <mat-form-field appearance="outline">
                                        <mat-label></mat-label>
                                        <mat-select (selectionChange)="tw.buildnum = $event.value;" [disabled]="isDisabled">
                                            <mat-select-trigger></mat-select-trigger> <!-- Show an empty menu, not the most recently selected item -->
                                            <mat-option *ngFor="let release of releases" [value]="release.end">{{release.name}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-2">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Vendor:</mat-label>
                                        <mat-select [(value)]="tw.vendor" [disabled]="isDisabled">
                                            <mat-option *ngFor="let item of QMSConst.TW_VENDORS" [value]="item">{{item}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div class="col-md-2">
                                    <mat-form-field appearance="outline">
                                        <mat-label>OS</mat-label>
                                        <mat-select [(value)]="tw.os" [disabled]="isDisabled">
                                            <mat-option *ngFor="let item of QMSConst.TW_OS" [value]="item">{{item}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div class="col-md-2">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Hardware</mat-label>
                                        <mat-select [(value)]="tw.hardware" [disabled]="isDisabled">
                                            <mat-option *ngFor="let item of QMSConst.TW_HARDWARE" [value]="item">{{item}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="row">
                                <mat-form-field appearance="outline">
                                    <mat-label>Comments</mat-label>
                                    <textarea [(ngModel)]='tw.comments' matInput style="min-height:100px;" cdkTextareaAutosize [disabled]="isDisabled"
                                        placeholder="Fill this out if 'other' selected in any of the above boxes.   e.g. Suite: Suite2008" autocomplete="off"></textarea>
                                </mat-form-field>
                            </div>

                            <!-- Tests -->
                            <div class="row">
                                        <!-- sortBy() is a custom function in our controller -->
                                <mat-card *ngFor="let test of tw.tests" 
                                            style="margin-bottom:15px; background-color:#ffffffdd;"
                                            [ngStyle]="{'margin-left': getTestLeftMargin(test) + 'px', 'margin-right': getTestRightMargin(test) + 'px'}">
                                    <div class="cool_test_label" style="margin-bottom: 20px"><b>Test #{{test.testid}}</b>
                                    </div>

                                    <div class="row" style="margin-bottom:5px"> <!-- 5px: add a little space between "Include in Master Test Plan" and the Result textarea -->
                                        <div style="float: right; margin-right:10px">
                                            <div *ngIf="test.inc_master" class="icon_plus">
                                                <i class="fa fa-plus"></i>
                                            </div>
                                            <div *ngIf="!test.inc_master" class="icon_minus">
                                                <i class="fa fa-minus"></i>
                                            </div>
                                        </div>
                                    </div>

                                    <table class="table cs_test_table">
                                        <tr>
                                            <td style="width:17%;">
                                                <mat-button-toggle-group [(value)] = "test.result" [disabled]="isDisabled"> <!-- vertical="true" -->
                                                    <mat-button-toggle value="Pass" [ngStyle]="{'background-color': (test.result == 'Pass') ? 'green' : 'inherit'}">Pass</mat-button-toggle>
                                                    <mat-button-toggle value="Fail" [ngStyle]="{'background-color': (test.result == 'Fail') ? 'red' : 'inherit'}">Fail</mat-button-toggle>
                                                    <mat-button-toggle value="Notest" [ngStyle]="{'background-color': (test.result == 'Notest') ? 'orange' : 'inherit'}">Not Tested</mat-button-toggle>
                                                </mat-button-toggle-group>
                                            </td>
                                            <td style="width:2%">
                                            </td>
                                            <td style="width:43%; vertical-align: top;">
                                                <b>Description: </b>
                                                {{test.description}}
                                            </td>
                                            <td style="width:3%">
                                            </td>
                                            <td style="width:35%; vertical-align: top;">
                                                <b>Expected Result</b>
                                                {{test.expected_result}}
                                            </td>
                                        </tr>
                                    </table>

                                    <div class="row" *ngIf="test.result == 'Fail' || test.result == 'Notest'">
                                        <div class="col-md-3">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Manual Ticket #</mat-label>
                                                <input matInput [(ngModel)]='test.ticketid' autocomplete="off" [disabled]="isDisabled"
                                                    placeholder="If you have already created a ticket for this issue, enter the ticket # here.">
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-9">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Comments</mat-label>
                                                <textarea [(ngModel)]='test.comments' matInput style="min-height:100px;" cdkTextareaAutosize [disabled]="isDisabled"
                                                    placeholder="Enter any additional information / deviations from the test description and result." autocomplete="off"></textarea>
                                            </mat-form-field>
                                        </div>
                                    </div> 

                                </mat-card>
                            </div>

                                <!-- Save button -->
                            <div class="row" *ngIf="!isDisabled">
                                <div class="col-md-1">
                                    <button [disabled]="isSaving" mat-raised-button color="primary" (click)="saveTW()">Save</button>
                                </div>
                                <div class="col-md-6">
                                    <b>{{saveResult}}</b>
                                    <b style="color:orange">{{invalidFieldErrMsg}}</b>
                                    <span *ngIf="isSaving" style="display: inline-block; height: 80px;">
                                        <mat-spinner [diameter]="progressSpinnerSize" style="margin: 15px;"></mat-spinner>
                                    </span>
                                </div>
                            </div>
                            
                        </mat-card>
                    </div>

                    <p style="color:red"><b>{{httpErrorMsg}}</b></p>
                    {{twMessage}}
                </div>
            </div>
        </div>
    </div>
</div>
