import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { UpgradeModule } from '@angular/upgrade/static';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

    // All Angular pages must be downgraded to angular.js to run (until we fully upgrade the entire site to be 100% modern Angular)
import { JobsPage } from './pages/jobs/jobs';
import { JobPage } from './pages/job/job';
import { TicketsPage } from './pages/tickets/tickets';
import { TicketPage } from './pages/ticket/ticket';
import { TestWorksheetPage } from './pages/tw/tw';
import { TestSummaryPage } from './pages/testsummary/testsummary';
import { DeveloperPage } from './pages/developer/developer';

    // Dialogs
import { CommentDialog } from './dialogs/comment/comment'
import { ConfirmDialog } from './dialogs/confirm/confirm'
import { MessageDialog } from './dialogs/message/message'
import { SelectDialog } from './dialogs/select/select'
import { ReviewJobDialog } from './dialogs/reviewjob/reviewJob'

    // Provide our AuthInterceptor HTTP interceptor that handles token stuff
    // See: https://angular.io/guide/http#intercepting-requests-and-responses
import { AuthInterceptor } from './app.services';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

    // Http interceptor providers in outside-in order
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
];

    // Angular Material stuff
import { MatFormFieldModule } from '@angular/material/form-field'; // MatButtonModule, MatInputModule
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatSelectModule } from '@angular/material/select';
// import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';

// DIDN'T GET THIS TO WORK: For Angular.js services we are upgrading to be available to Angular, that is done via ./ajs-upgraded-providers.ts,
// and added to the providers: [] part of the @NgModule below.
//import { AuthTokenProvider  } from './ajs-upgraded-providers';


@NgModule({
  declarations: [
        // Pages
    AppComponent,
    JobsPage,
    JobPage,
    TicketsPage,
    TicketPage,
    TestWorksheetPage,
    TestSummaryPage,
    DeveloperPage,

        // Dialogs
    ConfirmDialog,
    CommentDialog,
    MessageDialog,
    SelectDialog,
    ReviewJobDialog
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
	UpgradeModule,              // For legacy angular.js website
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,

        // Angular Material:
    MatInputModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatSelectModule,
  //  MatMenuModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatTableModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatChipsModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatTabsModule
  ],
  providers: [httpInterceptorProviders, Title]   // Anything that has @Injectable({providedIn: 'root'}) does NOT need to be put here!!
  //bootstrap: [AppComponent]  // We do NOT do this! Otherwise ngDoBootstrap() below will not be called!
})

export class AppModule {
    constructor(private upgrade: UpgradeModule) 
    { 
        console.log("AppModule constructor entered.");
    }

    ngDoBootstrap() {
        console.log("ngDoBootstrap() doing legacy upgrade with qms app.");
        this.upgrade.bootstrap(document.body, ['qms'], {});

    }
}

// 1) Downgrade Angular 2 components & pages to be usable from Angular.js

import * as angular from 'angular';
import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';

let ng1App = angular.module('qms');
	
    // There are TWO steps to add modern Angular pages! 
    // 1) Downgrade the component here, as seen below.
    // 2) Add the component (kebab-case, i.e. 'jobs-page' and 'job-page') to:    src/ng1/js/setup.js
    //    where the angular.js routes are set up!
    // 3) Also add the component to @NgModule's Declarations section above! It won't parse angular 2 directives in the HTML otherwise!!!
ng1App.directive('jobsPage', downgradeComponent({ component: JobsPage }) as angular.IDirectiveFactory);
ng1App.directive('jobPage', downgradeComponent({ component: JobPage }) as angular.IDirectiveFactory);
ng1App.directive('ticketsPage', downgradeComponent({ component: TicketsPage }) as angular.IDirectiveFactory);
ng1App.directive('ticketPage', downgradeComponent({ component: TicketPage }) as angular.IDirectiveFactory);
ng1App.directive('twPage', downgradeComponent({ component: TestWorksheetPage }) as angular.IDirectiveFactory);
ng1App.directive('testsummaryPage', downgradeComponent({ component: TestSummaryPage }) as angular.IDirectiveFactory);
ng1App.directive('developerPage', downgradeComponent({ component: DeveloperPage }) as angular.IDirectiveFactory);

// 2) Downgrade Angular 2 services that we want to be usable from Angular.js

import { QMSNetwork } from './qmsnetwork.service';
ng1App.factory('QMSNetwork', downgradeInjectable(QMSNetwork));

import {AuthTokens} from './app.services';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
ng1App.factory('AuthTokenFactory', downgradeInjectable(AuthTokens));

    // Make Angular 'Title' service available to angular.js
    // Usage: controller: ['customer', function(title: Title) {
ng1App.factory('Title', downgradeInjectable(Title));


console.log("app.module finished.");

// 3) NEVER GOT WORKING: Import ng1 classes that we've converted from factories to separate services, so that we can
//   upgrade them to be available to the modern Angular part of the app too!

// @ts-ignore
//import {AuthTokenFactoryClass} from 'src/ng1/js/services';
        // As we convert legacy factoies to class-based services, we'll register them here:
//ng1App.service('AuthTokenFactory', AuthTokenFactoryClass);
