import { AfterViewInit, OnInit, Component, Input, ViewChild, ɵdetectChanges } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FormControl } from '@angular/forms';
//import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { MatSelectChange } from '@angular/material/select';
import { MatDialog } from '@angular/material/dialog';
import { CommentDialog } from 'src/app/dialogs/comment/comment'
import { MessageDialog } from 'src/app/dialogs/message/message'
import { ConfirmDialog } from 'src/app/dialogs/confirm/confirm'
import { SelectDialog } from 'src/app/dialogs/select/select'
import { ReviewJobDialog } from 'src/app/dialogs/reviewjob/reviewJob'

import { QMSNetwork } from '../../qmsnetwork.service';
import { AuthTokens } from '../../app.services';
import { QMSUtils, QMSConst } from '../../qms.classes';
import { Utils } from '../../utils';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';


@Component({
  selector: 'tw-page',
  templateUrl: './tw.html',
  styleUrls: ['./tw.css']
})
export class TestWorksheetPage implements OnInit, AfterViewInit {

        // @ts-ignore
    @Input() jobid: string; // Passed in from a ng1 wrapper: see ng1/ng2/jobPageWrapper.js and ng1/setup.js
        // @ts-ignore
    @Input() twid: string;  // Test Worksheed ID (if 'new', it's a new worksheet)

    //selection = new SelectionModel<Job>(true, []);
    //numSelected: number = 0;

    readonly QMSConst = QMSConst;

    readonly progressSpinnerSize = 30;
    readonly minusOne = -1;     // So we can set an HTML element's value to -1, not the string "-1"

    tw:TestWorksheet = <TestWorksheet>{};
    isDisabled:boolean = false;

    job:Job = <Job>{};
    ticket:Ticket = <Ticket>{};
    users:CRMUser[] = [];             // Users part of Engineering group. Used to populate Owner menu, as well as by Reviews/Approvals system.
    releases = QMSConst.RELEASES;

    isLoading:boolean = false;
    isSaving:boolean = false;           // Used to instantly disable Save/Change buttons once they've been pressed until record has been saved & reloaded
    canSave:boolean = true;
    saveResult:string = "";             // shows "Success" to user after they click Save if save to server was successful
    invalidFieldErrMsg:string = "";
    currentDatabase:string = "";
    twMessage:string = "";
    httpErrorMsg:string = "";               // Set to an HTTP error message if various HTTP methods fail

        // Later: use this once we're a fully modern Angular site:  (private route: ActivatedRoute)
    constructor(private network: QMSNetwork, private authTokens:AuthTokens, public dialog: MatDialog, private titleService: Title) {

        console.log("//-----------------------------------------------");
        console.log("// Test Worksheet page constructor entered");
        console.log("//-----------------------------------------------");
    /*    this.route.queryParams.subscribe(params => {
            console.log("Got route param: ", params);
          });*/
        
        this.network.getDBInfo().subscribe(
            res => {
                console.log("getDBInfo got response: ", res);
                if (res.crmDBName != "qms" && res.designDBName != "design")
                    this.currentDatabase = "Using development databases. (CRM: " + res.crmDBName + ".  Design: " + res.designDBName + ")";
            },
            err => { this.httpErrorMsg = err.message}
       //     , () => console.log('getDBInfo() request completed.')
        );

        this.network.getUsersForGroup("QA")
            .subscribe( 
                res => {
                    this.users = res;
                    console.log("Got QA users: ", res);
                },
                err => { this.httpErrorMsg = err.message}
            //    , () => console.log('getUsersWithPermission() request completed.')
            );
    }

    ngOnInit() {
        console.log("Angular Test Worksheet page got jobid: ", this.jobid);
        console.log("Angular Test Worksheet page got twid: ", this.twid);
        this.titleService.setTitle( this.twid );
    //    this.loadJob( this.jobid );
    //    this.loadJobChanges( this.jobid );

        this.init();
    }

    ngAfterViewInit() {

    }

    init()
    {
            // Load existing test worksheet?
        if (this.twid != "new" && this.twid.length > 0) 
        {
                // Test worksheets load using the *MongDB _id*, i.e. something like "5b10742f0f0d08ac1e00002f"
            this.network.getTestWorksheet(this.twid)
                .subscribe( res => 
                {
                    console.log("Loaded test worksheet: ", res);
                    this.isLoading = false;
                    if (res)
                    {
                        this.tw = res;
                        QMSUtils.fixTestWorksheetSchema( this.tw );

                        this.setDisabled(true);  
                        this.isSaving = false;
                        this.updateReleasesShortcutMenu();
                    }
                    else
                        this.twMessage = "Test Worksheet " + this.twid + " does not exist.";
                },
                err => { this.httpErrorMsg = err.message}
            //    , () => console.log('getTestWorksheet() request completed.')
            );       
        } 
        else 
        {
                // Create new test worksheet for the jobid
                // Note: the tests will be set once the job is loaded (see loadJob)
            console.log("Not loading a test worksheet, since we are creating a new one instead.");

         //   this.tw.tester = this.authTokens.getUserMongoID();
            this.tw.jobid = this.jobid;
            this.tw.testerUsername = this.authTokens.getUser().username;
            this.isSaving = false;
            console.log("Setting tester username to: ", this.tw.testerUsername);
        }

            // TODO: Port this stuff
  /*      $http.get('/json/q.php?q=chngs&id=' + $scope.jobid).success(function(data) {
            $scope.changes = data;
        });  
*/

        this.loadJob( this.jobid );
    }

    loadJob( jobid:string ) {
        this.network.getJob( jobid )
            .subscribe( res => {
                if (res)
                {
                    this.job = res;

                        // This following code is new
                    QMSUtils.fixJobSchema( this.job );
                    console.log("Loaded job: ", this.job);

                    if (this.twid == "new") 
                    {
                            // New test worksheet? Copy tests from the job we just loaded.
                        this.tw.tests = JSON.parse( JSON.stringify(this.job.tests) );

                            // Also, if the job has no owner (it's in the 'Ready to Test QA' pool), assign it to this tester
                        if (this.job.owner == "")
                        {
                            this.network.setJobStatusAndOwner( jobid, this.job.statusNew, this.authTokens.getUser().username, "" )
                                .subscribe(
                                    res => {
                                        console.log("setJobStatusAndOwner() HTTP result: ", res);
                                    },
                                    err => { console.log("setJobStatus HTTP error:", err.message); }
                                //    , () => console.log('setJobStatusAndOwner() request completed.')
                                );
                        }
                    }
                    
                        // We can load any associated ticket after we've loaded the job.
                    this.loadTicket( this.job.ticketid );
                }
            },
            err => { this.httpErrorMsg = err.message}
         //    , () => console.log('getJob() request completed.')
        );
    }

    updateReleasesShortcutMenu()
    {
        this.releases = QMSConst.RELEASES;

        let branch = "";
        if (this.tw.suite == "2017")
            branch = "Suite2017";
        else if (this.tw.suite == "2015")
            branch = "Suite2015";
        else if (this.tw.suite == "2013")
            branch = "Suite2013";

        if (branch.length > 0)
        {
            this.releases = [];
            for (let n=0; n < QMSConst.RELEASES.length; n++)
                if (QMSConst.RELEASES[n].branch == branch)
                    this.releases.push( QMSConst.RELEASES[n] );
        }
    }

        // TODO: Implement this in HTML
    setDisabled( disabled:boolean )
    {
        this.isDisabled = disabled;
    }

    hasTicket() {
        return (this.job != undefined) && (this.job.ticketid != 'None')
            && (this.job.ticketid != '') && (this.job.ticketid != undefined);
    }

    loadTicket( ticketid:string ) {
            this.network.getTicket( ticketid )
                .subscribe( res => 
                {
                    if (res)
                    {
                        console.log("Got response from server: ", res);

                            // Server will return null when the record could not be found.
                        if (res == null)  {
                            console.log("FAILED TO LOAD TICKET: ", ticketid);
                        }
                        else
                        {
                            console.log("Got ticket: ", res);
                            this.ticket = res;
                            QMSUtils.fixTicketSchema( this.ticket );
                        }
                    }
                },
                err => { console.log("ERROR when attempting to load ticket" + ticketid, err.message); }
        //    , () => console.log('getJob() request completed.')
            );
    }

    reopenTicket() {
        /*
        $("#reopenTicket").submit();
        // reload in 2 seconds
        setTimeout(function() {$scope.loadTicketInfo();}, 2000);*/
    }

    /*
    <form action="http://tickets.csaim.com/scp/tickets.php?id={{ticket.id}}#reply" name="reply" id="closeTicket" method="post" target="debug" enctype="multipart/form-data">
 <input type="hidden" name="ticket_id" value="{{ticket.id}}">
 <input type="hidden" name="msg_id" value="{{ticket.msg_id}}">
 <input type="hidden" name="a" value="reply">
 <input type="hidden" name="canned" value="0">   
 <input type="hidden" name="response" value="Ticket Closed while testing job: {{jobid}}"> 
 <input type="hidden" name="signature" value="none"> 
 <input type="hidden" name="ticket_status" value="Close"> 
</form>

<form action="http://tickets.csaim.com/scp/tickets.php?id={{ticket.id}}#reply" name="reply" id="reopenTicket" method="post" target="debug" enctype="multipart/form-data">
 <input type="hidden" name="ticket_id" value="{{ticket.id}}">
 <input type="hidden" name="msg_id" value="{{ticket.msg_id}}">
 <input type="hidden" name="a" value="reply">
 <input type="hidden" name="canned" value="0">   
 <input type="hidden" name="response" value="Ticket reopened while testing job: {{jobid}}>"> 
 <input type="hidden" name="signature" value="none"> 
 <input type="hidden" name="ticket_status" value="Reopen"> 
</form>
*/
    closeTicket() {
        /*
        $("#closeTicket").submit();
        // reload in 2 seconds
        setTimeout(function() {$scope.loadTicketInfo();}, 2000);*/
    }

/*
        // TODO: Share this code with the job.ts page
    loadJobChanges( jobid:string ) {
        console.log("Loading job changes...");
        this.network.getJobChanges( jobid )
            .subscribe(
                res => {
                    this.jobChanges = res;

                        // We do the sorting ONCE on load, since this modifies the array in-place, which would trigger a changed detected
                        // if we used Utils.hasObjectChanged() on it. [We don't, but this is a better pattern anyway vs. calling sortBy from the HTML *ngFor loop]
                    this.sortBy( this.jobChanges, 'change');
                    console.log("Got job changes: ", res);
                },
                err => { this.httpErrorMsg = err.message}
                    , () => console.log('getJobChanges() request completed.')
            );
    }
*/

    /** @return empty array if no errors, otherwise array containing names of invalid fields */
    validateFields():string[]
    {
        let invalidFields:string[] = [];

            // 1) Validate top-level fields that are strings
        let fieldNames = ["vendor", "suite", "os", "hardware", "buildnum", "testerUsername"];
        for (let n=0; n < fieldNames.length; n++)
            if ( typeof (<any>this.tw)[fieldNames[n]] == 'undefined' || (<any>this.tw)[fieldNames[n]] == "" )
                invalidFields.push(fieldNames[n]);

            // 2) Also validate the tests array
        for (let n=0; n < this.tw.tests.length; n++)
            if (typeof this.tw.tests[n].result == 'undefined')
                invalidFields.push( "Test " + this.tw.tests[n].testid + " result" );

        return invalidFields;
    }

    saveTW()
    {
        console.log("user is ready to save tw: ", this.tw);

        let invalidFields = this.validateFields();
        if (invalidFields.length > 0)
        {
            this.invalidFieldErrMsg = "Not saved. Please enter a value for these fields: " + invalidFields.join(", ");
            return;
        }

            // Formerly the backend did this. I like doing it client-side better.
        let numPassed = 0, numFailed = 0, numNotTested = 0;
        for (let n=0; n < this.tw.tests.length; n++)
        {
            if (this.tw.tests[n].result == "Pass")
                numPassed++;
            else if (this.tw.tests[n].result == "Fail")
                numFailed++;
            else
                numNotTested++;
        }
        this.tw.num_pass = numPassed;
        this.tw.num_fail = numFailed;
        this.tw.num_nt = numNotTested;

        console.log("Saving test worksheet: ", this.tw );

        this.network.saveTestWorksheet( this.tw )
            .subscribe(
                res => {
                    if (res.worked)
                    {
                        this.saveResult = "Save successful.";
                        this.canSave = false;           // Once they've saved, they can't save again
                        console.log("Saved test worksheet: ", res);

                        if ( res._id && res._id.$oid )
                        {
                            this.twid = res._id.$oid;
                        //    this.init(); // Reload what we just saved
                            this.setDisabled(true);
                            window.location.href = "/#/tw/" + this.jobid + "/" + this.twid;
                        }

                        if (numFailed === 0 && numNotTested === 0)
                        this.moveJobToCompleteStatus();
                    }
                    else
                    {
                        this.saveResult = "Save failed.";
                        this.isSaving = false;
                        console.log("Save job failed. Server returned: ", res);
                    }
                },
                err => { this.saveResult = err.message; this.isSaving = false;}
            //    , () => console.log('saveJob() request completed.')
            
            );
    }

        // If all tests pass, move job to Complete status and owner to Geoff for final review
    moveJobToCompleteStatus()
    {
            // For now, all 'Complete' jobs go to Geoff for a final review
        let newOwner = QMSConst.DEFAULT_JOB_COMPELTE_OWNER;

            // This is supposed to be the changelist number, but I'm not sure it's even used or needed. We'd have to load the job's changes to get this. (see job.ts)
        let changelistNumber = ""; // this.jobChanges[this.jobChanges.length-1].change;
        this.network.reviewJob( this.jobid, this.job.statusNew, "Pass", "All tests passed. Automatically re-assigning job to " + newOwner + " and changing status to Complete.", QMSConst.JOB_STATUS_COMPLETE, changelistNumber, this.job.owner, newOwner )
            .subscribe(
                res => {
                    this.saveResult = "Save successful. Job has been reassigned to " + newOwner + " for final review.";
                },
                err => { console.log("HTTP ReviewJob failed: ", err.message);}
            //    , () => console.log('removeReview() request completed.')
            );
    }


// ----------------- Duplicate code from job.ts ------------------- //

    getTestLeftMargin(test:JobTest):number {
        return Utils.occurrences(test.testid, ".", false) * 20;
    }
    
    getTestRightMargin(test:JobTest):number {
        return Math.max(20, 100 - this.getTestLeftMargin(test) );
    }
}

