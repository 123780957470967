import { AfterViewInit, OnInit, Component, Input, ViewChild, ɵdetectChanges } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FormControl } from '@angular/forms';
//import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { MatSelectChange } from '@angular/material/select';
import { MatDialog } from '@angular/material/dialog';
import { CommentDialog } from 'src/app/dialogs/comment/comment'
import { MessageDialog } from 'src/app/dialogs/message/message'
import { ConfirmDialog } from 'src/app/dialogs/confirm/confirm'
import { SelectDialog } from 'src/app/dialogs/select/select'
import { ReviewJobDialog } from 'src/app/dialogs/reviewjob/reviewJob'

import { QMSNetwork } from '../../qmsnetwork.service';
import { AuthTokens } from '../../app.services';
import { JobStatus, QMSUtils, QMSConst } from '../../qms.classes';
import { Utils } from '../../utils';
import { stringify } from '@angular/compiler/src/util';
import { subscribeOn } from 'rxjs/operators';


@Component({
  selector: 'job-page',
  templateUrl: './job.html',
  styleUrls: ['./job.css']
})
export class JobPage implements OnInit, AfterViewInit {

        // @ts-ignore
    @Input() jobid: string; // Passed in from a ng1 wrapper: see ng1/ng2/jobPageWrapper.js and ng1/setup.js

    //selection = new SelectionModel<Job>(true, []);
    //numSelected: number = 0;

    readonly JobStatus = JobStatus; // Expose this class to our HTML template so it can call its methods
    readonly QMSConst = QMSConst;
    readonly Utils = Utils;

    readonly progressSpinnerSize = 30;
    // isLoading: boolean = true;

    job:Job = <Job>{};
    prevJob:Job = <Job>{};  // Used to determine if changes have been made
    changedFields:Array<string> = [];
    changedSentence: string = "";
    testprefix: number = 0;     // "1560" for "job001560"

        // Job changes is a separate MongoDB collection that's loaded separately based on the jobID
    jobChanges:JobChange[] = [];
    testWorksheets:TestWorksheet[] = [];

        // We don't update these fields directly on the job, because we don't want the general 'Save' button to save these changes.
        // Changes to these must go through the special changeStatusOwner() backend endpoint
    jobStatus:number = -1;
    jobOwner:string = "";
    statusMsg:string = "";                  // Set when the Change Status/Owner method succeeds or fails, or when the job loaded has an owner that isn't in the menu
    statusMenuItems:Array<{id:number, type:string}>  = [];

    currentDatabase:string = "";
    users:CRMUser[] = [];             // Users part of Engineering group. Used to populate Owner menu, as well as by Reviews/Approvals system.

    isChangingStatus:boolean = false;   // Show spinner until we hear back from the server once user clicks Change Owner/Status button
    isSaving:boolean = false;           // Used to instantly disable Save/Change buttons once they've been pressed until job has been saved & reloaded
    saveResult:string = "";             // shows "Success" to user after they click Save if save to server was successful

    isDisabled:boolean = false;         // Set to true if the job's status is Closed
    isChecked:boolean = false;

    jobNotFoundMsg:string = "";
    jobMayNotBeApprovedMsg:string = ""; // reason why the job cannot be approved
    approveJobMsg:string = "";
    httpErrorMsg:string = "";               // Set to an HTTP error message if various HTTP methods fail

        // Later: use this once we're a fully modern Angular site:  (private route: ActivatedRoute)
    constructor(private network: QMSNetwork, private authTokens:AuthTokens, private titleService: Title, public dialog: MatDialog) {

        console.log("//-----------------------------------------------");
        console.log("// Job page constructor entered");
        console.log("//-----------------------------------------------");
    /*    this.route.queryParams.subscribe(params => {
            console.log("Got route param: ", params);
          });*/
        
        console.log("Current user:", this.authTokens.getUser() );
        
        let statusKeys = Object.keys(QMSConst.JobStatusNumToString);
        for (let n=0; n < statusKeys.length; n++) {
            let key = parseInt(statusKeys[n]);
            this.statusMenuItems.push( {id: key, type: QMSConst.JobStatusNumToString[key] });
        }
        
        this.network.getDBInfo().subscribe(
            res => {
                console.log("getDBInfo got response: ", res);
                if (res.crmDBName != "qms" && res.designDBName != "design")
                    this.currentDatabase = "Using development databases. (CRM: " + res.crmDBName + ".  Design: " + res.designDBName + ")";
            },
            err => { this.httpErrorMsg = err.message}
       //     , () => console.log('getDBInfo() request completed.')
        );

        this.network.getUsersWithPermission("JOBS")
            .subscribe( 
                res => {
                    this.users = res;
                    console.log("Got users with JOBS permissions: ", res);

                        // We want to call validateJob() after both the job AND users (with JOBS permission) have loaded.
                        // We check for that both here and when the job is loaded. 
                    if ( this.job.jobid != undefined )
                        this.validateJob( this.job );
                },
                err => { this.httpErrorMsg = err.message}
            //    , () => console.log('getUsersWithPermission() request completed.')
            );

          // This WORKS! But creates a job in Perforce... don't overdo testing of it.
    /*   this.network.createP4Job( "VernJensen", "Minor", "Test job -- created via new CRM via HTTP endpoint!", "Job requirements here." )
            .subscribe( 
                res => {
                    console.log("createP4Job got response: ", res);
                },
                err => { this.httpErrorMsg = err.message}
            //    , () => console.log('getUsersWithPermission() request completed.')
            );
        
        this.network.closeP4Job( "job003555" )
            .subscribe( 
                res => {
                    console.log("closeP4Job got response: ", res);
                },
                err => { this.httpErrorMsg = err.message}
            //    , () => console.log('getUsersWithPermission() request completed.')
            );
    */
    }

    ngOnInit() {
        console.log("Angular Job page got jobid: ", this.jobid);
        this.titleService.setTitle( this.jobid );
        this.loadJob( this.jobid );
        this.loadJobChanges( this.jobid );
        this.loadTestWorksheets( this.jobid );
    }

    ngAfterViewInit() {

    }

    loadJobChanges( jobid:string ) {
        console.log("Loading job changes...");
        this.network.getJobChanges( jobid )
            .subscribe(
                res => {
                    this.jobChanges = res;

                        // We do the sorting ONCE on load, since this modifies the array in-place, which would trigger a changed detected
                        // if we used Utils.hasObjectChanged() on it. [We don't, but this is a better pattern anyway vs. calling sortBy from the HTML *ngFor loop]
                    Utils.sortBy( this.jobChanges, 'change');
                    console.log("Got job changes: ", res);
                },
                err => { this.httpErrorMsg = err.message}, 
                () => console.log('getJobChanges() request completed.')
            );
    }

    loadTestWorksheets( jobid:string ) {
        this.network.getTestWorksheets( jobid )
            .subscribe(
                res => {
                    this.testWorksheets = res;
                    for (let n=0; n < this.testWorksheets.length; n++)
                        QMSUtils.fixTestWorksheetSchema( this.testWorksheets[n] );

                    console.log("Got test worksheets associated with the job: ", this.testWorksheets );
                },
                err => { this.httpErrorMsg = err.message},
                () => console.log('getTestWorksheets() request completed.')
            ); 
    }

    loadJob( jobid:string ) {
        this.network.getJob( jobid )
            .subscribe(
                res => {
                    this.isSaving = false;

                    if (res)
                    {
                        this.job = res;
                        console.log("Loaded job: ", this.job);

                        this.testprefix = parseInt(jobid.substring(3), 10);  // OLD QMS code: parseInt("<?=$jobid?>".substring(3),10);

                            // This following code is new
                        QMSUtils.fixJobSchema( this.job );
                        this.validateJob( this.job );
                        
                    //    this.prevJob = $.extend({}, this.job); // Deep copy -- DOES NOT WORK!! Changing the job changes the copy too!!!
                        this.jobStatus = this.job.statusNew;
                        this.jobOwner = this.job.owner;
                        this.prevJob = JSON.parse( JSON.stringify( this.job ) ); // Deep copy that DOES work!!
                        
                             // Special case: keep track of the owner of the job when it is in the 'In Development' status
                        if ( this.job.developerOwner === undefined && this.job.statusNew == QMSConst.JOB_STATUS_IN_DEVELOPMENT)
                        {
                            this.job.developerOwner = this.job.owner;
                            this.network.saveJob( this.job, "[Automatic: remembering job owner at In Development]", "" ).subscribe();
                        }
                    
                        this.changedFields = [];
                        this.changedSentence = "";
                        this.isChangingStatus = false;

                        this.updateIsDisabled();
                        
                            // Do any required sorting
                        Utils.sortBy( this.job.tests, 'testid');
                        //    Utils.sortByDate( job.reviews );  // Reviews should already be in order! Sorting by date messes up any that were done before a date was included in the review schema.
                    }
                    else
                        this.jobNotFoundMsg = "No job exists for " + this.jobid;

                        // TODO: Disable everything if job is closed
                //   if (this.job.status >= 4) {
                //        $scope.setDisabled(true);
            },
            err => { this.httpErrorMsg = err.message}
         //    , () => console.log('getJob() request completed.')
        );
    }

        // Validate job and report to user a message if we see any problems with the job's schema
        // TODO: Add more here! Currently we ONLY validate the 'owner' field.
    validateJob( job:Job )
    {
        if ( this.users.length <= 0)
            return;

        let ownerIsValid = (job.owner == ""); // Empty string owners ARE valid (all closed jobs will have an empty string owner)
        if (!ownerIsValid)
            for (let user of this.users)
                if (job.owner == user.username)
                    ownerIsValid = true;

        if (!ownerIsValid)
            this.statusMsg = "ERROR: the job's owner '" + job.owner + "' does not exist as a user with 'JOBS' permission. Please add 'JOBS' permission for this user, or change this job's owner.";
    }

    updateIsDisabled()
    {
        this.isDisabled = (this.job.statusNew === QMSConst.JOB_STATUS_CLOSED);
    }

    saveButtonClicked(event:any)
    {
        let obj = {
            title: "Save Job Changes", 
            body: "Please describe your changes and then save.", 
            action: "Changed " + this.getChangedSentence(),
            comments: "", 
            requireComments: false, 
            okButtonName: "Save"
        };
        const dialogRef = this.dialog.open( CommentDialog, {width: '550px', data: obj} );

        dialogRef.afterClosed().subscribe( (result:{clickedOk:boolean}) => {
                // result: true (Okay), false (cancel), or undefined (clicked outside dialog to dismiss)
            if (result.clickedOk)
            {
                this.isSaving = true;
                
                this.network.saveJob( this.job, obj.action, obj.comments )
                    .subscribe(
                        res => {
                            if (res.worked)
                            {
                                this.loadJob( this.jobid );
                                this.saveResult = "Save successful.";
                            }
                            else
                            {
                                this.saveResult = "Save failed.";
                                this.isSaving = false;
                                console.log("Save job failed. Server returned: ", res);
                            }
                        },
                        err => { this.saveResult = err.message; this.isSaving = false;}
                    //    , () => console.log('saveJob() request completed.')
                    );
            }
            else
                console.log("User canceled the save.");
        });
    }
    
    changeStatusClicked(event:Event)
    {
        this.isChangingStatus = true;
        this.statusMsg = "";

        console.log("Updating status/owner to: ", this.jobStatus, this.jobOwner);

        this.network.setJobStatusAndOwner( this.job.jobid, this.jobStatus, this.jobOwner, this.job.owner ) // this.job.owner = previous owner (so we know whether to notify via email)
            .subscribe(
                res => {
                    this.isChangingStatus = false;
                    console.log("setJobStatus() HTTP result: ", res);
                    if (res.worked)
                    {
                        this.statusMsg = "Change successful.";
                        this.loadJob( this.jobid );
                    }
                    else
                        this.statusMsg = "Change Status/Owner failed. " + res.error;
                },
                err => { this.statusMsg = err.message; this.isChangingStatus = false;}
            //    , () => console.log('setJobStatusAndOwner() request completed.')
            );
    }

    branchChanged(event:any)
    {
        console.log("New job branches: ", this.job, this.job.branches);
    }

    statusOwnerChanged( event: MatSelectChange )
    {
        this.statusMsg = "";
        this.updateChangedFields();
    }

    selectFieldChanged( event: MatSelectChange )
    {
        console.log("Field changed: ", event);
        this.updateChangedFields();
    }

    textFieldChanged( event: Event )
    {
        console.log("Field changed: ", event);
        this.updateChangedFields();
    }

        // Returns an array of job property names that have changed, or an empty array if no changes.
    updateChangedFields()
    {
        this.saveResult = "";

        this.changedFields = [];
        let anyChanged = Utils.hasObjectChanged( this.job, this.prevJob, this.changedFields );

        this.changedSentence = anyChanged ? "Unsaved changes: " + this.getChangedSentence() : "";
    }

        // What has the user changed? Stored in 'action' field of the log on save changes to job
    getChangedSentence():string
    {
        let result = "";
        
        for (let n=0; n < this.changedFields.length; n++)
        {
            if ( this.changedFields[n] == "job_type" )
                result += "Type to '" + this.job.job_type + "'";
            else if (this.changedFields[n] == "risklevel")
                result += "Risk to '" + this.job.risklevel + "'";
            else
                result += this.changedFields[n];
            if (n < this.changedFields.length-1)
                result += ", ";
        }

        return result;
    }

    //----------------------------------------------------
    // Reviews and Approvals -- TODO: Redo this to new system Geoff described (see my .rtf notes)
    // WARNING: For reviews that are pending, but have not been Approved or Rejected yet, ONLY the 'type' and 'user' will be filled out.
    //    All other fields will be undefined!
    //----------------------------------------------------

    getJobApproveLabel() 
    {
        let newStatusCode = this.getJobNextStepStatusCode();
        return "Approve and move onto '" + QMSConst.JobStatusNumToReviewString[newStatusCode] + "'";
    }

    getJobCurrentStatusString()
    {
        switch (this.job.statusNew)
        {
            case QMSConst.JOB_STATUS_PLANNED:
                return "In Planning";
            case QMSConst.JOB_STATUS_IN_DEVELOPMENT:
                return "In Development";
            case QMSConst.JOB_STATUS_CODE_REVIEW:
                return "Pending Code Review";
            case QMSConst.JOB_STATUS_AIMDOC:
                return "Pending SRS/VTP Updates";
            case QMSConst.JOB_STATUS_TESTING:
                return "To Be Tested";
            case QMSConst.JOB_STATUS_COMPLETE:
                return "Pending Final Review";
            default:
                return QMSConst.JobStatusNumToReviewString[this.job.statusNew] || "";
        }
    }

    getJobFailLabel()
    {
        let newStatusCode = this.getJobPrevStepStatusCode();
        return "Fail and send back to '" + QMSConst.JobStatusNumToReviewString[newStatusCode] + "'";
    }

        // returns the job status to go to WHEN PASSING the current step
    getJobNextStepStatusCode()
    {
        switch (this.job.statusNew)
        {
            case QMSConst.JOB_STATUS_PLANNED:
                return QMSConst.JOB_STATUS_IN_DEVELOPMENT;
            case QMSConst.JOB_STATUS_IN_DEVELOPMENT:
                // [[fallthrough]]
            case QMSConst.JOB_STATUS_CODE_REVIEW:
                // JW 2022-04-26: skip AIMDOC in normal job lifecycle (revert the addition of AIMDOC) to align with SOP
                // return QMSConst.JOB_STATUS_AIMDOC;
                // [[fallthrough]]
            case QMSConst.JOB_STATUS_AIMDOC:
                return this.job.notests ? QMSConst.JOB_STATUS_COMPLETE : QMSConst.JOB_STATUS_TESTING;
            case QMSConst.JOB_STATUS_TESTING:
                return QMSConst.JOB_STATUS_COMPLETE;
            case QMSConst.JOB_STATUS_COMPLETE:
                return QMSConst.JOB_STATUS_CLOSED;
            case QMSConst.JOB_STATUS_CLOSED:
                // [[fallthrough]]
            default:
                console.log('JobPage.getJobNextStepStatusCode(): invalid current status' + this.job.statusNew);
                return this.job.statusNew;
        }
    }

        // return the job status to go to WHEN FAILING the current step
    getJobPrevStepStatusCode()
    {
        switch (this.job.statusNew)
        {
            case QMSConst.JOB_STATUS_IN_DEVELOPMENT:
                return QMSConst.JOB_STATUS_PLANNED;
            case QMSConst.JOB_STATUS_CODE_REVIEW:
                // [[fallthrough]]
            case QMSConst.JOB_STATUS_AIMDOC:
                // [[fallthrough]]
            case QMSConst.JOB_STATUS_TESTING:
                // [[fallthrough]]
            case QMSConst.JOB_STATUS_COMPLETE:
                return QMSConst.JOB_STATUS_IN_DEVELOPMENT;
            case QMSConst.JOB_STATUS_PLANNED:
                // [[fallthrough]]
            case QMSConst.JOB_STATUS_CLOSED:
                // [[fallthrough]]
            default:
                console.log('JobPage.getJobPrevStepStatusCode(): invalid current status' + this.job.statusNew);
                return this.job.statusNew;
        }
    }

    curUserIsJobOwner():boolean
    {
        return (this.job.owner === this.authTokens.getUser().username);
    }

    jobMayBeApproved():boolean
    {
        // already closed?
        if (this.job.statusNew === QMSConst.JOB_STATUS_CLOSED) {
            this.jobMayNotBeApprovedMsg = ""; // cannot move past JOB_STATUS_CLOSED but no error message either
            return false;
        }
        // only current job owner may approve unless the job is in JOB_STATUS_TESTING
        else if (!this.curUserIsJobOwner() && this.job.statusNew != QMSConst.JOB_STATUS_TESTING) {
            this.jobMayNotBeApprovedMsg = "Only the job's current owner can approve the job to the next stage.";
            return false;
        }
        // to move past JOB_STATUS_IN_DEVELOPMENT risklevel must be valid (yes or no)
        else if (this.job.statusNew == QMSConst.JOB_STATUS_IN_DEVELOPMENT) {
            if (this.job.risklevel !== 'yes' && this.job.risklevel !== 'no') {
                this.jobMayNotBeApprovedMsg = "Please specify a valid Risk assessment and save the job before approving it."
                return false;
            }
        }
        return true;
    }

    changeStatusToNextStep()
    {
        this.changeStatus("Pass", this.getJobNextStepStatusCode());
    }
    
    requestCodeReview()
    {
        this.changeStatus("Pass", QMSConst.JOB_STATUS_CODE_REVIEW);
    }

    failJob()
    {
        this.changeStatus("Fail", this.getJobPrevStepStatusCode());
    }

    changeStatus(passFail:"Pass"|"Fail", newStatus:number) 
    {
        let changelistNumber = "??";
        if (this.jobChanges.length > 0)
            changelistNumber = String(this.jobChanges[this.jobChanges.length-1].change);

            // If changing status to 'Complete', change owner to Vern by default
            // If doing a Code Review, make the new owner menu blank to force user to select a new owner.
        let defaultNewOwner = this.job.owner;
        if (newStatus == QMSConst.JOB_STATUS_COMPLETE)
            defaultNewOwner = QMSConst.DEFAULT_JOB_COMPELTE_OWNER;
        else if (newStatus == QMSConst.JOB_STATUS_AIMDOC)
            defaultNewOwner = QMSConst.DEFAULT_JOB_AIMDOC_OWNER;
        else if (newStatus == QMSConst.JOB_STATUS_CLOSED)
            defaultNewOwner = "";
        else if (newStatus == QMSConst.JOB_STATUS_CODE_REVIEW) {
            let pendingCodeReview = this.job.reviews.find((r) => r.type == QMSConst.JOB_STATUS_CODE_REVIEW && !r.status && r.user != "---");
            defaultNewOwner = (pendingCodeReview && pendingCodeReview.user) || "";
        }

            // Special case: if someone fails the job from 'Complete' back to 'In Development', automatically populate the 'Owner'
            // field in the dialog with the developer who originally owned the job when it was In Development.
        if (passFail === "Fail" && this.job.developerOwner !== undefined && newStatus == QMSConst.JOB_STATUS_IN_DEVELOPMENT)
            defaultNewOwner = this.job.developerOwner;

        let obj = {
            title: "Reviewing " + this.jobid, 
            body: this.authTokens.getUser().fullname + ": review for accuracy of requirements, design, test cases and code. CL#" + changelistNumber,
            comments: "",
            owner: defaultNewOwner,
            statusNew: newStatus,
            users: this.users,
            statusMenuItems: this.statusMenuItems,
            showStatusMenu: (passFail == "Fail"),
            warning: ""
        };
            // Special case: if going from 'In Development' to 'Documentation', bypassing 'Code Review'
            // but a code reviewer exists
        if (this.job.statusNew == QMSConst.JOB_STATUS_IN_DEVELOPMENT && newStatus == QMSConst.JOB_STATUS_AIMDOC) {
            let pendingCodeReview = this.job.reviews.find((r) => r.type == QMSConst.JOB_STATUS_CODE_REVIEW && !r.status && r.user != "---");
            if (pendingCodeReview) {
                obj.warning = "Bypassing Code Review originally assigned to [" + pendingCodeReview.user +
                    "], who will be removed.";
            }
        }
        const dialogRef = this.dialog.open( ReviewJobDialog, {width: '550px', data: obj} );
        
        this.approveJobMsg = "";
        dialogRef.afterClosed().subscribe( (result:{clickedOk:boolean}) => {
            if (result.clickedOk == true)
            {
                console.log("Job review complete. Data: ", obj);

                this.isChangingStatus = true;
                this.network.reviewJob( this.jobid, this.job.statusNew, passFail, obj.comments, obj.statusNew, changelistNumber, this.job.owner, obj.owner )
                    .subscribe(
                        res => {
                            this.approveJobMsg = "Success!";
                            console.log("ReviewJob backend result: ", res);
                            if (res.worked)
                                this.loadJob( this.jobid );
                        },
                        err => { console.log("HTTP ReviewJob failed: ", err.message);}
                    //    , () => console.log('removeReview() request completed.')
                    );
            }
        });
    }



        // BAD DESIGN: this requires the user to separately choose the user this review is assigned to, and then click Save Changes.
        // Howeveer, we don't need to redesign, since this entire system will be replaced with a better mechanism soon.
/*
    addReviewer() {  
        let obj = {
            title: "Add Reviewer", 
            body: "IMPORTANT: After clicking Add, you must select a user and then click Save Changes above for your addition to be saved.",
            selectLabel: "Review Type",
            selectOptions: this.QMSConst.OldJobReviewTypes,
            okButtonName: "Add"
        };
        const dialogRef = this.dialog.open( SelectDialog, {width: '550px', data: obj} );

        dialogRef.afterClosed().subscribe( (result:{clickedOk:boolean, selectChoice:number}) => {

            console.log("Got result from Select dialog: ", result);

            if (result.clickedOk == true)
            {
                this.job.reviews.push( { type: result.selectChoice, user:"" });
                this.updateChangedFields();

            //    this.network.saveJob( this.job, this.getChangedSentence(), "Added Job Reviewer" )
            //        .subscribe(
            //            res => { },
            //            err => { alert("Save failed." + err.message);},
            //            () => this.loadJob( this.jobid )
            //        );
            }
        });

    
        opts = {
            backdrop: true,
            backdropClick: true,
            templateUrl: '/tpl/addreview.html',
            resolve : {
                types : () => { return this.QMSConst.JobReviewTypes }
            },
            controller: 'AddReviewCtrl'
        };        
        var d = $dialog.dialog(opts);
        d.open().then((result) => {
            if (result !== undefined) {
                this.job.reviews.push( {type: result.type} );
            }
        });
    
    }
    */

        // TODO: Remmove this functionality.
        // GEOFF'S NOTE (4/6/2021): This is something that doesn’t work quite right… occasionally we get duplicate reviews and the intention is to be able to remove the duplicate (even if it’s marked Pass/Fail). 
        // If/when we rework the approval process this will likely go away with whatever we replace it with. So, I wouldn’t spend too much time on it. 
        // User clicked the trash icon button by a review in Reviews/Approvals section
        // Since the entire job is re-loaded after the server deletes the review, we let the user know if they'll lose unsaved changes first.
/*
    removeReview(username:string, type:number) {
        this.updateChangedFields();
        if (this.changedFields.length > 0)
        {
            let obj = {
                title: "Discard Unsaved Changes", 
                body:"You have unsaved changes to the job. If you delete this item, you will lose those unsaved changes. Discard changes and delete this review?"
            };
            const dialogRef = this.dialog.open( ConfirmDialog, {width: '550px', data: obj} );

            dialogRef.afterClosed().subscribe( (result:boolean) => {
                if (result == true)
                    this.finishRemoveReview(username, type);
            });
        }
        else
            this.finishRemoveReview(username, type);
    }

    finishRemoveReview(username:string, type:number)
    {
        console.log("removing review for '"+username+"' of type '"+type+"'");
        
        this.network.removeReview( this.job.jobid, type, username )
            .subscribe(
                res => {
                    console.log("RemoveReview backend result: ", res);
                    if (res.worked)
                        this.loadJob( this.jobid );
                },
                err => { console.log("Delete review failed: ", err.message);}
            //    , () => console.log('removeReview() request completed.')
            );
    }
*/

    getReviewIndex(type:number, username:string) {
        for (let i=0; i < this.job.reviews.length; i++)
            if (this.job.reviews[i].user == username && this.job.reviews[i].type == type)
                return i;
        return -1;
    }

    getReviewTypeString(typeid:number) {

        let statusKeys = Object.keys(QMSConst.JobStatusNumToReviewString);
        for (let n=0; n < statusKeys.length; n++) {
            let key = parseInt(statusKeys[n]);
            if (typeid == key)
                return QMSConst.JobStatusNumToReviewString[key];
        }

        for (let i=0; i < this.QMSConst.OldJobReviewTypes.length; i++)
            if (this.QMSConst.OldJobReviewTypes[i].id == typeid)
                return this.QMSConst.OldJobReviewTypes[i].type;
        return "Unknown review type: " + typeid;
    }
    
    /*
    getTickets( tw:TestWorksheet ) {
        var ret="";
        if (tw.tests == undefined)
          return "";
        for (i=0 ;i < tw.tests.length; i++) {
          if (tw.tests[i].ticketid != ""  && tw.tests[i].ticketid != undefined)
            ret += tw.tests[i].ticketid + " ";
        }
        return ret;
      }*/

    //----------------------------------------------------
    // Job tests - from old QMS
    //----------------------------------------------------

    addTestButtonPushed() { 
        this.job.notests = false;   // The 'No Tests' checkbox should no longer be checked
        this.job.tests.push({guid: Utils.generateGUID(), description: "", expected_result: "",  parenttest: "", 
                            testid: this.getNextTestId(""), inc_master: false});
        Utils.sortBy( this.job.tests, 'testid');
        this.updateChangedFields(); 
    }

    addChildTestButtonPushed( test:JobTest ) {
        let parentguid = test.guid;
        this.job.tests.push( {guid: Utils.generateGUID(), description: "", expected_result: "",  parenttest: <string>parentguid, 
                                testid: this.getNextTestId(parentguid), inc_master: false} ); 
        Utils.sortBy( this.job.tests, 'testid');   // REQUIRED for subtests to show up directly under its parent instead of at end of list!
        this.updateChangedFields();  
    }

    deleteTestButtonPushed( testid:string ) { 
            // delete child tests and this test
        for (var i = this.job.tests.length-1; i >= 0; i--) {
            if (this.job.tests[i].testid.indexOf(testid) == 0) {
                this.job.tests.splice(i, 1); 
            }
        }

        this.updateChangedFields();
    }

    getTestLeftMargin(test:JobTest):number {
        return Utils.occurrences(test.testid, ".", false) * 20;
    }
    
    getTestRightMargin(test:JobTest):number {
        return Math.max(20, 100 - this.getTestLeftMargin(test) );
    }

        // For job003519, returns "3519.01" for first test, and "3519.01.01" for a subtest of the first test, and "3519.02" for the second test
    getNextTestId( parentguid:string|undefined ):string {
        let level:number = 0;
        let max:number = 0;

        if (typeof parentguid == 'undefined')
            return "getNextTestID: error: invalid parentid";

        console.log("getNextTestID called for parentid: ", parentguid);

            // This is a child ID we are creating?
        if ( parentguid && parentguid != "") {
            var prefix = this.job.tests[ this.getJobTestIdx(<string>parentguid) ].testid;
            level = Utils.occurrences(prefix, ".", false) + 1;
                // Determine the highest test number so far of all test siblings (for the right-most component) i.e. for 3519.03, the highest is "3" if none are higher
            for (var i=0; i < this.job.tests.length; ++i) {
             /*   if (Utils.startsWith(this.job.tests[i].testid, prefix + ".")) {
                    let x:string[] = this.job.tests[i].testid.split(".");

                        // TODO: Should we do:   let num = parseInt(x[level], 10)    and use num below??
                        // Think through logic.
                    let xInt = parseInt(x[level]);
                    if (x.length > level && !isNaN(xInt) && xInt > max)
                        max = parseInt(x[level]);
                }*/

                if (Utils.startsWith(this.job.tests[i].testid, prefix + ".")) {
                    var x:string[] = this.job.tests[i].testid.split(".");
                    if (x.length > level && !isNaN(<any>x[level]) && <any>x[level] > max)
                      max = parseInt(x[level]);
                 }
            }

            console.log("getNextTestId got prefix", prefix, " and max ", max);

                // Prefix is the parent testid. The rest is the new sibling component of the id. (i.e. "03")
            return prefix + "." + ( "0" + (max+1)).slice(-2);
        } 
        else {
                // Generate a root-level test ID
            let maxId = 0;
                // find the max "root level" ID
            for (var i=0; i < this.job.tests.length; ++i) {
                let x:string[] = this.job.tests[i].testid.split(".");  

                let xInt = parseInt(x[1]);
                if (x.length > 1 && !isNaN(xInt) && xInt > maxId)
                    maxId = xInt;
            }         
            return this.testprefix + "." + ( "0" + (maxId+1)).slice(-2);
        }
    }

        // Not efficient, but doesn't matter the way it's used
    getJobTestIdx( parentguid:string ) {
        for (var i=0; i < this.job.tests.length; ++i) {
            if (this.job.tests[i].guid == parentguid)
                return i;
        }
        return 0;
    }

    noTests() { return !(this.job.tests.length>0) }
//    noChanges() { return !(this.changes.length>0) }    // Code Changes
//    noTW() { return !(this.tw.length>0) }  // TW = Test Worksheet
}

