// This file is referenced in angular.json as the 'main' file.

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

				



if (environment.production) {
  enableProdMode();
}
 
console.log("Bootstrapping Angular 2 app from main.ts.");

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
